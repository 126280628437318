import { Grid, TablePagination } from "@mui/material";
import React from "react";
import MyDonationCard from "./MyDonationCard";
import NoDonation from "./NoDonation";

import { isEmpty } from "lodash";

const MyDonation = ({ myDonation, handleChangePage, page, rowsPerPage }) => {
  const { results, count, next, previous } = myDonation;

  if (isEmpty(results) && !next && !previous) {
    return <NoDonation />;
  }

  return (
    <div className="profile_mydonation ">
      <div className="profile_mydonation_header ">
        <h2>Bağışlarım</h2>
      </div>
      <div className="profile_mydonation_cards ">
        {results &&
          results.map((e, i) => (
            <Grid
              className="donationcardCol"
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              key={i}
            >
              <MyDonationCard data={e} />
            </Grid>
          ))}
      </div>
      <TablePagination
        component="div"
        count={count}
        page={page}
        onPageChange={(e, value) => handleChangePage(e, value, myDonation)}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10]}
      />
    </div>
  );
};

export default MyDonation;
