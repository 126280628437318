import { createContext, useContext, useMemo } from "react";
import { useCookies } from "react-cookie";
import api from "../../services/api";

import { EventStore } from "../events";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [cookies, setCookies, removeCookie] = useCookies();
  const login = async ({ username, phone_number, password }) => {
    const res = await api.post("/api/token/", {
      username: username ? username : phone_number,
      password: password,
    });
    setCookies("token", res.data.token);
    window.location.reload();
    // token
    return res;
  };

  const { openLogin, setOpenLogin } = useContext(EventStore);

  const logout = () => {
    localStorage.removeItem("userInfo");
    ["token", "name"].forEach((obj) => removeCookie(obj)); // remove data save in cookies
    setOpenLogin(true);
    localStorage.removeItem("token");

    window.location.reload();
    setOpenLogin({
      ...openLogin,
      load: true,
    });
    return "logout";
  };

  const value = useMemo(
    () => ({
      cookies,
      login,
      logout,
    }),
    [cookies]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useAuth = () => {
  return useContext(UserContext);
};
