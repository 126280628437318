import {
  Box,
  ButtonGroup,
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, { useEffect, useRef, useState } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import CartModal from "../CartModal";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const CartItemCard = ({
  data,
  counter,
  lamount,
  shortCard,
  handleDuplicate,
  handleDelete,
  token,
}) => {
  const [open, setOpen] = useState(false);
  const [total] = useState(data.amount);

  const handleClose = () => {
    setOpen(false);
  };

  const [cartUserInfo] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleCloseEditCart = () => {
    setShowModal(!showModal);
  };

  const [editMenu, setEditMenu] = useState(false);

  const handleClickEditMenu = (event) => {
    setEditMenu(!editMenu);
  };
  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setEditMenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <>
      {shortCard ? (
        <>
          <div className="cart_item_cart shortCard">
            <div className="cart_item_cart_image shortCard">
              <CardMedia
                component="img"
                height="100%"
                image={data.image}
                title="image"
              />
            </div>
            <div className="cart_item_cart_text shortCard">
              <h2>{data.name}</h2>
              {!token && (
                <div>₺{data.amount ? data.amount : data.quantity_price}</div>
              )}
              {token && total}
            </div>
            <ButtonGroup
              size="small"
              color="success"
              orientation="vertical"
              aria-label="vertical outlined button group"
            >
              <Button onClick={() => handleDuplicate(data)}>+</Button>
              <Button disabled>{counter}</Button>
              <Button onClick={() => handleDelete(data)}>-</Button>
            </ButtonGroup>
          </div>
        </>
      ) : (
        <div className="cart_item_cart">
          <div className="cart_item_left">
            <div className="cart_item_cart_image">
              <CardMedia
                component="img"
                height="100%"
                image={data.image}
                title="image"
              />
            </div>
            <div className="cart_item_cart_text">
              <h2>{data.name}</h2>
              {!token && (
                <p>₺{data.amount ? data.amount : data.quantity_price}</p>
              )}
              {token && total}
            </div>
          </div>

          <div className="cart_item_right">
            {lamount && (
              <div className="cart_item_cart_side_total">
                {!token && (
                  <p className="cart-single-price">
                    <span className="lira-symbol">₺</span>{" "}
                    {data.amount ? data.amount : data.quantity_price}
                  </p>
                )}

                {token && total}

                <ButtonGroup
                  size="small"
                  color="success"
                  aria-label="small outlined button group"
                >
                  <Button onClick={() => handleDelete(data)}>-</Button>
                  <Button disabled>{counter}</Button>
                  <Button onClick={() => handleDuplicate(data)}>+</Button>
                </ButtonGroup>

                <div ref={ref}>
                  <IconButton
                    aria-label="more"
                    aria-haspopup="true"
                    onClick={handleClickEditMenu}
                  >
                    <MoreVertIcon />
                  </IconButton>

                  {editMenu && (
                    <div className="editmenu">
                      {/* <MenuItem onClick={() => handleDuplicate(data)}>
                        {" "}
                        <ContentCopyIcon
                          style={{ marginRight: 5, fontSize: "15px" }}
                        />
                        Çokla
                      </MenuItem> */}
                      <MenuItem onClick={() => handleDelete(data)}>
                        <DeleteIcon
                          style={{ marginRight: 5, fontSize: "15px" }}
                        />
                        Sil
                      </MenuItem>
                    </div>
                  )}
                </div>
              </div>
            )}
            {showModal && (
              <CartModal
                cardData={cartUserInfo}
                dynamicDonationAmount={data.amount}
                handleClose={handleCloseEditCart}
              />
            )}
          </div>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Use Google's location service?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Let Google help apps determine location. This means sending
                anonymous location data to Google, even when no apps are
                running.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Disagree</Button>
              <Button onClick={handleClose} autoFocus>
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default CartItemCard;
