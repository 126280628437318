import React from "react";
import Header from "./Header";
import "./layout.css";
import { HelmetConfig } from "../utils/helmetConfig";

const Layout = ({ notice, children }) => {
  return (
    <div>
      <HelmetConfig />
      <Header />
      {children}
    </div>
  );
};

export default Layout;
