import React from 'react'
import { Link } from 'react-router-dom'
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
const NoDonation = () => {
  return (
    <div className='nodonation'>
       <div>
       <VolunteerActivismIcon />
        <h2>Henüz hiç bağışınız bulunmamaktadır.</h2>
        <Link to="/">Bağış yap</Link>
       </div>
    </div>
  )
}

export default NoDonation