import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CardMedia,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Layout from "../../layout";
import api from "../../services/api";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toast } from "react-toastify";

const Bank = () => {
  const [banks, setBanks] = useState([]);

  useEffect(() => {
    const fetchBank = async () => {
      let res = await api.get("/api/donor/banks/");
      if (res) {
        setBanks(res?.data);
      }
    };
    fetchBank();
  }, []);

  const copyClip = (e) => {
    navigator.clipboard.writeText(e);
    toast.success(`Kopyalandı`);
  };
  const copyCliptwo = (e) => {
    navigator.clipboard.writeText(e);
    toast.success(`Kopyalandı`);
  };
  const copyClipThree = (e) => {
    navigator.clipboard.writeText(e);
    toast.success(`Kopyalandı`);
  };
  const copyClipFour = (e) => {
    navigator.clipboard.writeText(e);
    toast.success(`Kopyalandı`);
  };
  return (
    <Layout>
      <div className="banka_area">
        <Container>
          <h1 className="banka_title">Hesap Numaralarımız</h1>
          <div className="banka_content">
            <Grid container justifyContent={"center"}>
              <Grid item xs={12} sm={12} md={8}>
                {banks &&
                  banks.map((e) => (
                    <Accordion className="banka-card">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <CardMedia
                          className="bankimage"
                          component="img"
                          image={e.image}
                          title="image"
                        />
                        <Typography
                          className="accotitle"
                          sx={{ color: "text.secondary" }}
                        >
                          {e.name}
                        </Typography>
                      </AccordionSummary>

                      {e.bank_accounts.map((e) => (
                        <div className="bank-list">
                          <h2 className="currency">{e.currency}</h2>
                          <div className="acc_ibn">
                            {e.account_number && (
                              <div className="account">
                                <h3>Hesap Numarası</h3>
                                <button
                                  value={e.account_number}
                                  onClick={(e) => copyCliptwo(e.target.value)}
                                >
                                  {e.account_number}
                                  <ContentCopyIcon />
                                </button>
                              </div>
                            )}
                            {e.iban_no && (
                              <div className="account">
                                <h3>IBAN</h3>
                                <button
                                  value={e.iban_no}
                                  onClick={(e) => copyClip(e.target.value)}
                                >
                                  {e.iban_no}
                                  <ContentCopyIcon />
                                </button>
                              </div>
                            )}
                          </div>
                          <Divider variant="middle" />
                          {e.description && (
                            <p>Hesap İşlevi: {e.description}</p>
                          )}
                          {e.account_name && (
                            <p className="bank-other-copy">
                              Hesap Adı:{" "}
                              <button
                                value={e.account_name}
                                onClick={(e) => copyClipThree(e.target.value)}
                              >
                                {e.account_name}
                                <ContentCopyIcon />
                              </button>
                            </p>
                          )}
                          {e.branch && (
                            <p>
                              Şube: <span> {e.branch}</span>
                            </p>
                          )}
                          {e.branch_no && (
                            <p>
                              Şube Numarası: <span> {e.branch_no}</span>
                            </p>
                          )}
                          {e.swift_no && (
                            <p className="bank-other-copy">
                              Swift Kodu:
                              <button
                                value={e.swift_no}
                                onClick={(e) => copyClipFour(e.target.value)}
                              >
                                {e.swift_no}
                                <ContentCopyIcon />
                              </button>
                            </p>
                          )}
                        </div>
                      ))}
                    </Accordion>
                  ))}
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default Bank;
