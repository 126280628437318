import {
  CardMedia,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { CurrentUserStore } from "../../hooks/auth/currentUser";
import api from "../../services/api";

import CartModal from "../CartModal";
const Card = ({ data, btncolor, handleCardDesciption }) => {
  const [dynamicDonationAmount, setDynamicDonationAmount] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { userState } = useContext(CurrentUserStore);
  const handleCartModal = () => {
    setShowModal(!showModal);
  };
  const handleClose = () => {
    setShowModal(!showModal);
  };

  const [userInfo, setUserInfo] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      const headers = {
        headers: {
          Authorization: "Bearer " + userState.token,
        },
      };
      try {
        let { data } = await api.get("/api/management/users/me", headers);
        setUserInfo(data);
      } catch (error) {}
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="card-area">
        <div className="image">
          <CardMedia
            component="img"
            height="100%"
            image={data.image}
            title="image"
            style={{ cursor: "pointer" }}
            onClick={() => handleCardDesciption(data)}
          />
        </div>
        <div className="details">
          <h2
            style={{ cursor: "pointer" }}
            onClick={() => handleCardDesciption(data)}
          >
            {data.name}
          </h2>
          {/* if env ITEM_DESC is true show data.description */}
          {process.env.REACT_APP_ITEM_DESC === "true" && (
            <span
            className="card-description"
              style={{ cursor: "pointer" }}
              onClick={() => handleCardDesciption(data)}
            >
              {data.description}
            </span>
          )}
          <div className="card-footer">
            <FormControl
              inputprops={{
                readOnly: data.quantity_price ? true : false,
              }}
              fullWidth
              sx={{ m: 1 }}
            >
              <InputLabel htmlFor="outlined-adornment-amount">
                Amount
              </InputLabel>
              <OutlinedInput
                value={
                  data.donation_type === "Static"
                    ? data.quantity_price
                    : dynamicDonationAmount
                }
                className="cardInput"
                id="outlined-adornment-amount"
                placeholder="00.00"
                onChange={(e) => setDynamicDonationAmount(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <span className="liraSymbol liraSymbol_card">₺</span>
                  </InputAdornment>
                }
              />
            </FormControl>

            <button
              style={{ background: btncolor }}
              className="card-btn"
              onClick={() => handleCartModal(data)}
            >
              Bağış Yap
            </button>
          </div>
        </div>
      </div>
      {showModal && (
        <CartModal
          cardData={data}
          dynamicDonationAmount={dynamicDonationAmount}
          handleClose={handleClose}
          userInfo={userInfo}
        />
      )}
    </>
  );
};

export default Card;
