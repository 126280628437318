import React, { useState } from "react";
import Card from "./common/Card";
import { Container } from "@mui/system";
import { Grid } from "@mui/material";
import CartDescription from "./CartDescription";

const CategoryCards = ({ cardData, btncolor }) => {
  const [showDesciption, setShowDesciption] = useState(false);
  const [desciptionData, setDesciptionData] = useState("false");

  const handleCardDesciption = (data) => {
    setShowDesciption(!showDesciption);
    setDesciptionData(data);
  };
  const handleCloseDesciption = (data) => {
    setShowDesciption(!showDesciption);
  };
  return (
    <div className="section_area acilYardim_area">
      <Container>
        <Grid className="cardGrid" container rowSpacing={2}>
          {cardData
            ?.filter((e) => e.is_published === true)
            .map((e, index) => (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={4} key={index}>
                <Card
                  handleCardDesciption={handleCardDesciption}
                  data={e}
                  btncolor="var(--primary)"
                />
              </Grid>
            ))}
        </Grid>
      </Container>

      {showDesciption && (
        <CartDescription
          desciptionData={desciptionData}
          handleClose={handleCloseDesciption}
        />
      )}
    </div>
  );
};

export default CategoryCards;
//mx
//sm mx 900
//md mx 1200
