import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import CartFormCommon from "./common/CartFormCommon";
import { CurrentUserStore } from "../hooks/auth/currentUser";
import api from "../services/api";
const UserInfo = ({
  cardData = [],
  dynamicDonationAmount = 0,
  handleNext,
  handleBack,
  validation,
  userInfos,
}) => {
  const userData = JSON.parse(localStorage.getItem("userInfo"));
  let initialValue = 0;

  if (userData?.group_name) {
    initialValue = 1;
  }
  if (userData?.corporate_name) {
    initialValue = 2;
  }
  const [value, setValue] = useState(initialValue);
  const { userState } = useContext(CurrentUserStore);
  const [total, setTotal] = useState(0);
  const [myCartItems, setMyCartItems] = useState([]);
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography variant="div">{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleContinue = (data, cardFormType) => {
    if (data.first_name && data.last_name && data.email && data.phone_number) {
      if (cardFormType === "group" && data.group_name) {
        return handleNext();
      } else if (cardFormType === "corporate" && data.corporate_name) {
        return handleNext();
      } else if (cardFormType === "personal") {
        return handleNext();
      } else {
      }
    } else {
    }
  };

  const [userInfo, setUserInfo] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      const headers = {
        headers: {
          Authorization: "Bearer " + userState.token,
        },
      };
      try {
        let { data } = await api.get("/api/management/users/me", headers);
        setUserInfo(data);
      } catch (error) {}
    };
    fetchData();
  }, []);

  useEffect(() => {
    const headers = {
      headers: {
        Authorization: "Bearer " + userState.token,
      },
    };
    const fetchData = async () => {
      try {
        let { data } = await api.get("/api/payment/cart/", headers);
        let myCartItems = data.cart_items.map((e) => {
          return {
            donation_item: e.donation_item,
            amount: e.amount,
          };
        });
        setMyCartItems(myCartItems);
      } catch (error) {}
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (userState.token) {
      const totalAmount = myCartItems?.reduce(
        (p, c) => p + Number(c.amount),
        0
      );
      setTotal(totalAmount);
    } else {
      const cartFromStorage = JSON.parse(localStorage.getItem("cartItems"));
      const totalAmount = cartFromStorage?.reduce(
        (p, c) =>
          c.quantity_price
            ? p + Number(c.quantity_price)
            : p + Number(c.amount),
        0.0
      );
      setTotal(totalAmount);
    }
  }, [myCartItems, userState.token]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={8}>
          <Box sx={{ width: "100%" }}>
            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                indicatorColor="white"
                className="cartTab"
              >
                <Tab
                  className="cartTabBtn"
                  label="Bireysel"
                  {...a11yProps(0)}
                />
                <Tab className="cartTabBtn" label="Grup" {...a11yProps(1)} />
                <Tab
                  className="cartTabBtn"
                  label="Organizasyon"
                  {...a11yProps(2)}
                />
              </Tabs>
            </Box>
            <TabPanel className="tabBoxs" value={value} index={0}>
              <CartFormCommon
                dynamicDonationAmount={dynamicDonationAmount}
                cardFormType="personal"
                cardData={cardData}
                handleContinue={handleContinue}
                handleBack={handleBack}
                noCart={true}
                validation={validation}
                userInfo={userInfos}
              />
            </TabPanel>
            <TabPanel className="tabBoxs" value={value} index={1}>
              <CartFormCommon
                dynamicDonationAmount={dynamicDonationAmount}
                cardFormType="group"
                cardData={cardData}
                handleContinue={handleContinue}
                handleBack={handleBack}
                noCart={true}
                validation={validation}
                userInfo={userInfos}
              />
            </TabPanel>
            <TabPanel className="tabBoxs" value={value} index={2}>
              <CartFormCommon
                dynamicDonationAmount={dynamicDonationAmount}
                cardFormType="corporate"
                cardData={cardData}
                handleContinue={handleContinue}
                handleBack={handleBack}
                noCart={true}
                validation={validation}
                userInfo={userInfos}
              />
            </TabPanel>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <div className="donation_payment_info_total cart-user-info">
            <h1 className="intotalHeading">Bağış Sepeti</h1>
            <div className="intotalDonation">
              Toplam Bağış : <br />{" "}
              <span className="mainPrice">
                <span className="liraSymbol">₺</span> {total}.00
              </span>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default UserInfo;
