import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";

import Layout from "../../layout";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import api from "../../services/api";
import useFetch from "../../hooks/localFetch";
import Notice from "../../components/Notice";
import { Avatar, Backdrop, CircularProgress } from "@mui/material";

import CategoryCards from "../../components/CategoryCards";

const Home = () => {
  const [value, setValue] = useState("1");
  const [tabContent, setTabContent] = useState([]);
  const fetchData = useFetch("/api/donor/categories/");
  const { data } = fetchData;
  const [openLoader, setOpenLoader] = useState(false);

  useEffect(() => {
    setOpenLoader(true);
    const fetchAllCategory = async () => {
      try {
        const catRes = await api.get(`/api/donor/categories/`);
        if (catRes.data) {
          fetchItems(catRes.data[0].id);
          setOpenLoader(false);
        }
      } catch (error) {
        setOpenLoader(false);
      }
    };
    fetchAllCategory();
  }, []);

  const handleChange = async (event, newValue) => {
    fetchItems(newValue);
    setValue(newValue);
  };

  const fetchItems = async (newValue) => {
    setOpenLoader(true);
    try {
      const catRes = await api.get(`/api/donor/categories/${newValue}`);
      const itemsRes = await api.get(`/api/donor/items`);

      if (catRes) {
        itemsRes.data.forEach((item) => {
          catRes.data.items.forEach((cat, index) => {
            if (
              cat.name === item.name &&
              cat.description === item.description
            ) {
              catRes.data.items[index] = { ...item };
            }
          });
        });
        setTabContent(catRes.data);
        setOpenLoader(false);
      }
    } catch (error) {
      setOpenLoader(false);
    }
  };
  const handleClose = () => {
    setOpenLoader(false);
  };
  return (
    <Layout>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box sx={{ width: "100%", typography: "body1" }} className="allItems">
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Container>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                className="tab-btn"
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
              >
                {data?.map((e) => (
                  <Tab
                    icon={<Avatar src={e.image} className="tab-icon" />}
                    label={e.name}
                    value={String(e.id)}
                    key={e.id}
                    className="tab-select"
                  />
                ))}
              </TabList>
            </Container>
            <Notice />
          </Box>
          {tabContent.items && (
            <CategoryCards cardData={tabContent.items} btncolor="red" />
          )}
        </TabContext>
      </Box>
    </Layout>
  );
};

export default Home;
