import { createContext, useEffect, useReducer } from "react";
import api from "../../services/api";
const CurrentUserStore = createContext();

// cart reducer start
var cookies = document.cookie
  .split(";")
  .reduce(
    (ac, str) =>
      Object.assign(ac, { [str.split("=")[0].trim()]: str.split("=")[1] }),
    {}
  );
const initvalue = {
  currentUser: "",
  token: cookies.token ? cookies.token : "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_USER":
      return {
        ...state,
        currentUser: action.payload,
      };
    case "ADD_TOKEN":
      return {
        ...state,
        token: action.payload,
      };
    case "REMOVE_TOKEN":
      return {
        ...state,
        token: "",
      };
    default:
      return state;
  }
};
// cart reducer end

///////////////////////////////////////////////////////////////////////////////////////
const CurrentUserProvide = (props) => {
  const [userState, userDispatch] = useReducer(reducer, initvalue);
  const fetchCurrentUser = async () => {
    const headers = {
      headers: {
        Authorization: "Bearer " + userState.token,
      },
    };
    try {
      let { data } = await api.get("/api/management/users/me", headers);

      userDispatch({
        type: "ADD_USER",
        payload: data,
      });
    } catch (error) {
    }
  };

  const value = { userState, userDispatch, fetchCurrentUser };
  useEffect(() => {
    fetchCurrentUser();
  }, []);

  return (
    <CurrentUserStore.Provider value={value}>
      {props.children}
    </CurrentUserStore.Provider>
  );
};

export { CurrentUserStore, CurrentUserProvide };
