import { Box, Grid, Hidden, Step, StepLabel, Stepper } from "@mui/material";
import {
  FaShoppingBag,
  FaAddressCard,
  FaCheck,
  FaCreditCard,
} from "react-icons/fa";
import { Container } from "@mui/system";
import React, { useContext, useEffect, useRef, useState } from "react";
import DonationCompleted from "../../components/DonationCompleted";
import MyDonationBasket from "../../components/MyDonationBasket";
import PayementInfo from "../../components/PayementInfo";
import { DonationStore } from "../../hooks/donationCart";
import Layout from "../../layout";
import api from "../../services/api";
import { CurrentUserStore } from "../../hooks/auth/currentUser";
import UserInfoCommon from "../../components/UserInfoCommon";
import { useLoggedInCartContext } from "../../hooks/donationCart/loginCart";
// import uuid from "react-uuid";
import { nanoid } from "nanoid";

const steps = [
  "Bağış Sepetim",
  "Bağışçı Bilgileri",
  "Ödeme Bilgileri",
  "Ödeme Tamamlandı",
];
const Cart = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [cartItems, setCartItems] = useState([]);
  const [cartLoader, setCartLoader] = useState(false);
  const { state, dispatch } = useContext(DonationStore);
  const { userState } = useContext(CurrentUserStore);
  const { cartItemsCount, setCartItemsCount } = useLoggedInCartContext();

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function QontoStepIcon(props) {
    if (props.icon === 1) {
      return (
        <Hidden smDown>
          <div
            className={`allStepsLabel stepOne ${
              props.active || props.completed ? "borderGreen" : "borderGray"
            }`}
          >
            {" "}
            <FaShoppingBag
              fontSize={21}
              color={props.active || props.completed ? "var(--primary)" : "#f1f1f1"}
            />
          </div>
        </Hidden>
      );
    }
    if (props.icon === 2) {
      return (
        <Hidden smDown>
          <div
            className={`allStepsLabel stepOne ${
              props.active || props.completed ? "borderGreen" : "borderGray"
            }`}
          >
            <FaAddressCard
              fontSize={21}
              color={props.active || props.completed ? "var(--primary)" : "#f1f1f1"}
            />
          </div>
        </Hidden>
      );
    }
    if (props.icon === 3) {
      return (
        <Hidden smDown>
          <div
            className={`allStepsLabel stepOne ${
              props.active || props.completed ? "borderGreen" : "borderGray"
            }`}
          >
            <FaCreditCard
              fontWeight={800}
              fontSize={21}
              color={props.active || props.completed ? "var(--primary)" : "#f1f1f1"}
            />
          </div>
        </Hidden>
      );
    }
    if (props.icon === 4) {
      return (
        <Hidden smDown>
          <div
            className={`allStepsLabel stepOne ${
              props.active || props.completed ? "borderGreen" : "borderGray"
            }`}
          >
            <FaCheck
              fontWeight={800}
              fontSize={21}
              color={props.active || props.completed ? "var(--primary)" : "#f1f1f1"}
            />
          </div>
        </Hidden>
      );
    }
  }

  useEffect(() => {
    if (userState.token) {
      const fetchData = async () => {
        const headers = {
          headers: {
            Authorization: "Bearer " + userState.token,
          },
        };
        try {
          setCartLoader(true);
          let { data } = await api.get("/api/payment/cart/", headers);
          let cartItem = data;

          if (data) {
            let { data } = await api.get("/api/donor/items/");
            let allItems = data;
            const mergedArr = cartItem.cart_items?.map((item1) => {
              const item2 = allItems.find(
                (item2) => item2.id === item1.donation_item
              );
              return { ...item1, ...item2, donationId: item1.id };
            });

            setCartLoader(false);
            setCartItems(mergedArr);
          }
        } catch (error) {
          window.location.reload();
          setCartLoader(false);
        }
      };
      fetchData();
    } else {
      setCartItems(state.cartItems);
      setCartLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.token]);

  const handleDuplicate = async (data) => {
    let body = {
      donation_item: data.donation_item,
      amount: data.quantity_price ? data.quantity_price : data.amount,
    };
    if (userState.token) {
      const headers = {
        headers: {
          Authorization: "Bearer " + userState.token,
        },
      };
      try {
        let res = await api.post("/api/payment/cart/items/", body, headers);

        if (res) {
          let { data } = await api.get("/api/donor/items/");
          const item2 = data.find(
            (item2) => item2.id === res.data.donation_item
          );

          let newObj = {
            ...item2,
            ...res.data,
            donationId: res.data.id,
          };
          setCartItems([...cartItems, newObj]);
          setCartItemsCount((prevState) => ++prevState);
        }
        return res;
      } catch (error) {}
    } else {
      const id = nanoid();

      dispatch({
        type: "ADD_CART",
        payload: {
          ...data,
          donation_item: data.id,
          amount: data.quantity_price ? data.quantity_price : data.amount,
          uid: id,
          quantity: 1,
        },
      });
    }
  };

  const handleDelete = async (data) => {
    if (userState.token) {
      // const filterData = cartItems.filter(
      //   (e) => e.donationId !== data.donationId
      // );

      // setCartItems(filterData);

      const headers = {
        headers: {
          Authorization: "Bearer " + userState.token,
        },
      };
      try {
        await api.delete(`/api/payment/cart/items/${data.donationId}`, headers);
        setCartItemsCount((prevState) => --prevState);
      } catch (error) {}
    } else {
      const cartItemsForStorage = JSON.parse(localStorage.getItem("cartItems"));
      const storeDlt = cartItemsForStorage.filter((e) => e.uid !== data.uid);
      localStorage.setItem("cartItems", JSON.stringify(storeDlt));
      dispatch({ type: "REMOVE_ITEM", payload: data });
    }
  };

  const handleDeleteAll = async () => {
    if (userState.token) {
      const headers = {
        headers: {
          Authorization: "Bearer " + userState.token,
        },
      };
      try {
        await api.post("/api/payment/cart/clear/", {}, headers);
        setCartItemsCount((prevState) => --prevState);
      } catch (error) {}
    } else {
      localStorage.setItem("cartItems", JSON.stringify([]));
      dispatch({
        type: "CLEAR_CART",
      });
    }
  };

  const ref = useRef();

  useEffect(() => {
    (async () => {
      // Merge Start

      if (userState.token) {
        const headers = {
          headers: {
            Authorization: "Bearer " + userState.token,
          },
        };
        setCartLoader(true);
        let { data } = await api.get("/api/payment/cart/", headers);
        let cartItem = data;

        if (data) {
          let { data } = await api.get("/api/donor/items/");
          let allItems = data;
          const mergedArr = cartItem.cart_items?.map((item1) => {
            const item2 = allItems.find(
              (item2) => item2.id === item1.donation_item
            );
            return { ...item1, ...item2, donationId: item1.id };
          });

          setCartLoader(false);
          setCartItems(mergedArr);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItemsCount]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        // setAnchorEl("close");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref]);

  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);

  useEffect(() => {
    if (queryParams.get("bank_status_code") === "00") {
      dispatch({
        type: "CLEAR_CART",
      });
      localStorage.removeItem("userInfo");
      localStorage.removeItem("cartItems");
      if (userState.token) {
        handleDeleteAll();
      }
      setActiveStep(3);
    } else if (queryParams.get("details")) {
      setActiveStep(2);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [userInfo, setUserInfo] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      const headers = {
        headers: {
          Authorization: "Bearer " + userState.token,
        },
      };
      try {
        let { data } = await api.get("/api/management/users/me", headers);
        setUserInfo(data);
      } catch (error) {}
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Layout>
      <Container>
        <div className="cartPage">
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Box sx={{ width: "100%" }} className="cartStep">
                <Stepper className="rootStepper" activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step key={index} {...stepProps}>
                        <StepLabel
                          StepIconComponent={QontoStepIcon}
                          {...labelProps}
                        >
                          {label}
                        </StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Box>

              <React.Fragment>
                <div className="stepContent">
                  {activeStep === steps.length ? (
                    <React.Fragment>
                      <DonationCompleted />
                    </React.Fragment>
                  ) : activeStep === 0 ? (
                    <div className="myDonationBasket">
                      <MyDonationBasket
                        cartLoader={cartLoader}
                        handleNext={handleNext}
                        handleBack={handleBack}
                        token={userState.token}
                        cartItems={
                          userState.token ? cartItems : state.cartItems
                        }
                        setCartItems={setCartItems}
                        handleDuplicate={handleDuplicate}
                        handleDelete={handleDelete}
                        handleDeleteAll={handleDeleteAll}
                      />
                    </div>
                  ) : activeStep === 1 ? (
                    <div className="paymentInfo">
                      <UserInfoCommon
                        handleNext={handleNext}
                        handleBack={handleBack}
                        validation={false}
                        userInfos={userInfo}
                      />
                    </div>
                  ) : activeStep === 2 ? (
                    <div className="paymentInfo">
                      <PayementInfo
                        handleNext={handleNext}
                        handleBack={handleBack}
                        token={userState.token}
                        handleDeleteAll={handleDeleteAll}
                      />
                    </div>
                  ) : activeStep === 3 ? (
                    <div className="donationCompleted">
                      <DonationCompleted
                        handleNext={handleNext}
                        handleBack={handleBack}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </React.Fragment>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Layout>
  );
};

export default Cart;
