import { createContext, useState, useContext } from "react";
const DonationStore = createContext(0);

export const LoggedInCartContext = ({ children }) => {
  const [cartItemsCount, setCartItemsCount] = useState(0);

  const value = {
    cartItemsCount,
    setCartItemsCount,
  };

  return (
    <DonationStore.Provider value={value}>{children}</DonationStore.Provider>
  );
};

export const useLoggedInCartContext = () => {
  const { cartItemsCount, setCartItemsCount } = useContext(DonationStore);
  return {
    cartItemsCount,
    setCartItemsCount,
  };
};
