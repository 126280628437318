import { createContext, useState } from "react";

export const EventStore = createContext({
    openLogin:false,
    load:false
});


export const EventProvider = ({ children }) => {
  const [openLogin, setOpenLogin] = useState(false);
  const value = { openLogin, setOpenLogin };

  return <EventStore.Provider value={value}>{children} </EventStore.Provider>;
};
