import React, { useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import PhoneInput from "react-phone-number-input";
import { CurrentUserStore } from "../../hooks/auth/currentUser";

const groupLocalData = {
  id: 5,
  name: "group_name",
  Icon: PersonIcon,
  placeholder: "Grup Adı",
};

const corporateLocalData = {
  id: 6,
  name: "corporate_name",
  Icon: PersonIcon,
  placeholder: "Kurum Adı",
};

let userLocalData = [
  {
    id: 1,
    name: "first_name",
    Icon: PersonIcon,
    placeholder: "Ad",
  },
  {
    id: 2,
    name: "last_name",
    Icon: PersonIcon,
    placeholder: "Soyad",
  },
  {
    id: 3,
    name: "email",
    Icon: EmailIcon,
    placeholder: "Email",
  },
  {
    id: 4,
    name: "phone_number",
    placeholder: "Telefon Numarası",
  },
];

const CartFormCommon = ({
  dynamicDonationAmount,
  handleCart,
  noCart,
  handleContinue,
  handleBack,
  validation,
  cardFormType,
  cardData,
  userInfo,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    control,
    formState: { errors },
  } = useForm();

  if (cardFormType === "group") {
    const existingGroupIndex = userLocalData.findIndex(
      (item) => item.name === "group_name"
    );
    const existingCorporateIndex = userLocalData.findIndex(
      (item) => item.name === "corporate_name"
    );
    if (existingCorporateIndex >= 0) {
      userLocalData.splice(existingCorporateIndex, 1);
    }
    if (existingGroupIndex < 0) {
      userLocalData.unshift(groupLocalData);
    }
  }
  if (cardFormType === "corporate") {
    const existingGroupIndex = userLocalData.findIndex(
      (item) => item.name === "group_name"
    );
    const existingCorporateIndex = userLocalData.findIndex(
      (item) => item.name === "corporate_name"
    );

    if (existingGroupIndex >= 0) {
      userLocalData.splice(existingGroupIndex, 1);
    }
    if (existingCorporateIndex < 0) {
      userLocalData.unshift(corporateLocalData);
    }
  }
  if (cardFormType === "personal") {
    const existingGroupIndex = userLocalData.findIndex(
      (item) => item.name === "group_name"
    );
    const existingCorporateIndex = userLocalData.findIndex(
      (item) => item.name === "corporate_name"
    );
    if (existingGroupIndex >= 0) {
      userLocalData.splice(existingGroupIndex, 1);
    }
    if (existingCorporateIndex >= 0) {
      userLocalData.splice(existingCorporateIndex, 1);
    }
  }
  const { userState } = useContext(CurrentUserStore);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userInfo"));

    let defaultValues = {};
    if (userState.token) {
      defaultValues.first_name = userInfo?.first_name;
      defaultValues.last_name = userInfo?.last_name;
      defaultValues.email = userInfo?.email;
      defaultValues.phone_number = userInfo?.phone_number;
      defaultValues.amount = cardData.quantity_price
        ? cardData.quantity_price
        : dynamicDonationAmount;
    } else {
      defaultValues.first_name = userData?.first_name;
      defaultValues.last_name = userData?.last_name;
      defaultValues.email = userData?.email;
      defaultValues.phone_number = userData?.phone_number;
      defaultValues.amount = cardData.quantity_price
        ? cardData.quantity_price
        : dynamicDonationAmount;
    }
    if (userData?.group_name) defaultValues.group_name = userData?.group_name;
    if (userData?.corporate_name)
      defaultValues.corporate_name = userData?.corporate_name;
    reset({ ...defaultValues });

    return () => {
      const data = getValues();
      if (cardFormType === "personal") {
        localStorage.setItem(
          "userInfo",
          JSON.stringify({
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            phone_number: data.phone_number,
          })
        );
      } else if (cardFormType === "group") {
        localStorage.setItem(
          "userInfo",
          JSON.stringify({
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            phone_number: data.phone_number,
            group_name: data.group_name,
          })
        );
      } else {
        localStorage.setItem(
          "userInfo",
          JSON.stringify({
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            phone_number: data.phone_number,
            corporate_name: data.corporate_name,
          })
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data) => {
    if (noCart) {
      handleContinue(data, cardFormType);
    } else {
      handleCart({
        ...cardData,
        userData: data,
        dynamic_price: Math.round(data?.amount),
      });
    }
  };

  const onSubmitWithCart = async (data) => {
    if (noCart) {
      handleContinue(data);
    } else {
      handleCart({
        ...cardData,
        userData: data,
        dynamic_price: Math.round(data?.amount),
        add_to_cart: true,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {!noCart && (
        <>
          <div className="cart-form-input amount">
            <span className="liraSymbol liraSymbol_cart">₺</span>
            <input
              {...register("amount", { required: true })}
              placeholder="00.00"
              readOnly={cardData.donation_type === "Static" && true}
            />
          </div>
          <div className="cart_errors">
            {errors.amount && <div>*Tutar gerekli.</div>}
          </div>
        </>
      )}

      <div className="cart-form-info">
        {userLocalData.map((item) => (
          <div key={item.id} className="cart-form-input info">
            {item.Icon && <item.Icon />}
            {item.name === "phone_number" ? (
              <Controller
                className="country-phone"
                name="phone_number"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    className="country-phone-input"
                    value={value}
                    international
                    required
                    onChange={onChange}
                    defaultCountry="TR"
                    limitMaxLength={10}
                  />
                )}
              />
            ) : (
              <input
                {...register(item.name, {
                  required: validation ? false : true,
                })}
                placeholder={item.placeholder}
                // type="email"
              />
            )}
          </div>
        ))}
      </div>

      {!validation && (
        <div className="cart_errors">
          {errors.email && <div>*Email gerekli.</div>}
          {errors.first_name && <div>*Ad gerekli.</div>}
          {errors.last_name && <div>*Soyad gerekli.</div>}
          {errors.phone_number && <div>*Telefon gerekli.</div>}
          {cardFormType === "group" && errors.group_name && (
            <div>*Grup adı gerekli.</div>
          )}
          {cardFormType === "corporate" && errors.corporate_name && (
            <div>*Kurum adı gerekli.</div>
          )}
        </div>
      )}
      <div className="cart-modal-btn">
        {noCart ? (
          <button className="cart-modal-btn-addCart" onClick={handleBack}>
            Geri
          </button>
        ) : (
          <button
            className="cart-modal-btn-addCart"
            onClick={handleSubmit(onSubmitWithCart)}
          >
            Sepete Ekle
          </button>
        )}

        <button
          className="cart-modal-btn-donate"
          onClick={handleSubmit(onSubmit)}
        >
          {noCart ? "Devam" : "Bağış Yap"}
        </button>
      </div>
    </form>
  );
};

export default CartFormCommon;
