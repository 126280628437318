import { useEffect, useState } from "react";
import api from "../../services/api";
const useFetch = (url) => {
  const [data, setdata] = useState(null);
  const [loading, setloading] = useState(true);
  const [error, seterror] = useState("");
  useEffect(() => {
     const fetchData = async()=>{
         await api.get(url)
          .then((data) => {
            seterror(data.error);
            setdata(data.data);
            setloading(false);
          });
     }
     fetchData()
  }, [url]);
  return { data, loading, error };
};
export default useFetch;
