import { UserProvider } from "./auth";
import { CurrentUserProvide } from "./auth/currentUser";
import { StoreProvide } from "./donationCart";
import { LoggedInCartContext } from "./donationCart/loginCart";
import { EventProvider } from "./events";

const AppProvider = ({ children }) => (
  <LoggedInCartContext>
    <UserProvider>
      <CurrentUserProvide>
        <EventProvider>
          <StoreProvide>{children}</StoreProvide>
        </EventProvider>
      </CurrentUserProvide>
    </UserProvider>
  </LoggedInCartContext>
);

export default AppProvider;
