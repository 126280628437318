import { CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAuth } from "../hooks/auth";
import { CurrentUserStore } from "../hooks/auth/currentUser";
import api from "../services/api";

import Select from "react-select";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

let isChangedCountry = false;
const GeneralInfo = ({ data, token, fetchUserData }) => {
  const [gpassword, setGPassword] = useState("");
  const [setStatesId] = useState("");
  const [gPassLoad, setGpassLoad] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);
  const [changeBtnLoad, setChangeBtnLoad] = useState(false);
  const [countrys, setCountrys] = useState([]);
  const [newPassword, setNewPassword] = useState("");
  const [meter, setMeter] = useState(false);
  const [states, setStates] = useState([]);
  const [selectedOption, setSelectedOption] = useState({
    value: data.country?.id,
    label: data.country?.name,
  });

  const [selectedOptionStates, setSelectedOptionStates] = useState({
    value: data.state?.id,
    label: data.state?.name,
  });

  const [showPasswordO, setShowPasswordO] = useState(false);
  const [showPasswordN, setShowPasswordN] = useState(false);
  const [showPasswordC, setShowPasswordC] = useState(false);
  const [showPasswordEyeO, setShowPasswordEyeO] = useState(false);
  const [showPasswordEyeN, setShowPasswordEyeN] = useState(false);
  const [showPasswordEyeC, setShowPasswordEyeC] = useState(false);

  const togglePasswordVisibilityO = () => {
    setShowPasswordO(!showPasswordO);
  };
  const togglePasswordVisibilityN = () => {
    setShowPasswordN(!showPasswordN);
  };
  const togglePasswordVisibilityC = () => {
    setShowPasswordC(!showPasswordC);
  };

  const handleForShowEyeO = (e) => {
    if (!e.target.value) {
      setShowPasswordEyeO(false);
    } else {
      setShowPasswordEyeO(true);
    }
  };
  const handleForShowEyeN = (e) => {
    if (!e.target.value) {
      setShowPasswordEyeN(false);
    } else {
      setShowPasswordEyeN(true);
    }
  };
  const handleForShowEyeC = (e) => {
    if (!e.target.value) {
      setShowPasswordEyeC(false);
    } else {
      setShowPasswordEyeC(true);
    }
  };

  const objectToaster = (obj,callback) => {
    Object.values(obj).map((err) => {
      callback(err?.[0]);
    });
  };

  const { userState, fetchCurrentUser } = useContext(CurrentUserStore);
  const { logout } = useAuth();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: async () => ({
      username: userState?.currentUser?.username,
      first_name: userState?.currentUser?.first_name,
      last_name: userState?.currentUser?.last_name,
      email: userState?.currentUser?.email,
      phone_number: userState?.currentUser?.phone_number,
      gender: userState?.currentUser?.gender
        ? userState?.currentUser?.gender?.toLowerCase()
        : "other",
      get_in_touch: userState?.currentUser?.is_approved_to_be_in_touch,
    }),
  });

  const onSubmitPassword = async ({
    old_password,
    new_password,
    confirm_password,
  }) => {
    if (!(old_password && newPassword && confirm_password)) {
      toast.error(`Bilgiler boş olmamalı!`);
      return;
    }
    if (newPassword !== confirm_password) {
      toast.error(`Parolalar eşleşmiyor.`);
      return;
    }
    let passwordStatus = await changePassword(old_password, confirm_password);
    if (!passwordStatus) {
      return;
    }
    if (passwordStatus?.response?.status == 400) {
      objectToaster(passwordStatus?.response?.data, toast.error)
      // toast.error(`${passwordStatus?.response?.data?.old_password}`);
    }
  };

  const onSubmit = async ({
    username,
    first_name,
    last_name,
    email,
    phone_number,
    gender,
    get_in_touch,
  }) => {
    setBtnLoad(true);

    const headers = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    function camelize(str) {
      return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
          return index === 0 ? word.toUpperCase() : word.toLowerCase();
        })
        .replace(/\s+/g, "");
    }

    let body = {
      username: email,
      first_name: first_name,
      last_name: last_name,
      email: email,
      phone_number: phone_number,
      gender: camelize(gender),
      country: selectedOption.value,
      state_province: selectedOptionStates.value,
      is_approved_to_be_in_touch: get_in_touch,
    };

    try {
      let res = await api.put("/api/management/users/me/", body, headers);
      if (res) {
        setBtnLoad(false);
        if (username !== data.username) {
          toast.success(`Email güncellendi.`);
          logout();
        }
        if (email !== data.email) {
          toast.success(`Email güncellendi.`);
          logout();
        }

        if (first_name !== data.first_name) {
          toast.success(`Ad güncellendi.`);
          fetchUserData();
        }
        if (last_name !== data.last_name) {
          toast.success(`Soyad güncellendi.`);
          fetchUserData();
        }
        if (phone_number !== data.phone_number) {
          toast.success(`Telefon numarası güncellendi.`);
          fetchUserData();
        }
        if (gender !== data.gender.toLowerCase()) {
          toast.success(`Cinsiyet güncellendi.`);
          fetchUserData();

          return;
        }
        if (data.country?.id !== selectedOption.value) {
          toast.success(`Ülke güncellendi.`);
          fetchUserData();
          return;
        }
      }
    } catch (error) {
      setBtnLoad(false);
      toast.error(`${error?.response?.data?.details}`);
    }
  };

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  useEffect(() => {
    fetchCountry();
    let defaultValues = {};
    defaultValues.username = data.username;
    defaultValues.first_name = data.first_name;
    defaultValues.last_name = data.last_name;
    defaultValues.email = data.email;
    defaultValues.phone_number = data.phone_number;

    defaultValues.gender = data.gender ? data.gender.toLowerCase() : "other";
    defaultValues.get_in_touch = data.is_approved_to_be_in_touch;

    reset({ ...defaultValues });
  }, [gpassword]);

  const changePassword = async (old_password, confirm_password) => {
    setChangeBtnLoad(true);
    if (old_password && newPassword && confirm_password) {
      const passBody = {
        old_password: old_password,
        new_password: newPassword,
        confirm_new_password: confirm_password,
      };
      const headers = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      try {
        let passRes = await api.put(
          "api/management/users/me/password-change/",
          passBody,
          headers
        );
        setChangeBtnLoad(false);
        if (passRes) {
          logout();
          return true;
        }
      } catch (error) {
        setChangeBtnLoad(false);
        return error;
      }
    }
  };

  const onCountryChange = (newValue, actionMeta) => {
    setSelectedOption({ ...newValue });
    isChangedCountry = true;
  };

  const onStateChange = (newValue, actionMeta) => {
    setSelectedOptionStates({ ...newValue });
  };

  const fetchCountry = async () => {
    try {
      let resCountry = await api.get("/api/management/countries/");
      if (resCountry) {
        let countrys = resCountry.data.map((e) => {
          return {
            value: e.id,
            label: e.name,
          };
        });
        setCountrys(countrys);
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleCountry(selectedOption.value);
  }, [selectedOption]);

  const handleCountry = async (id) => {
    try {
      let { data } = await api.get(`/api/management/countries/${id}`);
      if (data) {
        let states = data.state_provinces.map((e) => {
          return {
            value: e.id,
            label: e.name,
          };
        });
        if (isChangedCountry) {
          setSelectedOptionStates({
            value: data?.state_provinces[0]?.id,
            label: data?.state_provinces[0]?.name,
          });
          isChangedCountry = false;
        }
        setStates(states);
      }
    } catch (error) {}
  };

  const generatePassword = async () => {
    setGpassLoad(true);
    const { data } = await api.get("/api/management/generate-password/");
    if (data) {
      setGPassword(data.password);
      setGpassLoad(false);
    }
    return;
  };

  const checkPasswordCheck = async (e) => {
    if (gpassword) {
      if (e.target.checked) {
        await navigator.clipboard.writeText(gpassword);
        toast.success(`Password copied!`);
      } else {
        await navigator.clipboard.writeText("");
        toast.warning(`Copied text has been undo!`);
      }
    }
  };

  const atLeastOneNumeric = /[0-9]/g; // numbers from 0 to 9
  const atLeastOneSpecialChar = /[#?!@$%^&*-]/g; // any of the special characters within the square brackets
  const eightCharsOrMore = /.{12,}/g; // eight characters or more

  const passwordTracker = {
    number: newPassword.match(atLeastOneNumeric),
    specialChar: newPassword.match(atLeastOneSpecialChar),
    eightCharsOrGreater: newPassword.match(eightCharsOrMore),
  };

  return (
    <div className="profile_general">
      <form className="profile-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="profile_generl_profile">
          <h2>Genel Bilgiler</h2>
        </div>
        <Grid
          className="profilegenerlGrid"
          container
          spacing={{ xs: 1, md: 3 }}
        >
          <Grid item xs={12} sm={12} md={6}>
            <div className="form-input profile">
              <label className="profile-label" htmlFor="email">
                E-posta
              </label>
              <input
                {...register("email", { required: true })}
                placeholder="E-posta"
              />
              {errors.email && <p>email is required.</p>}
            </div>
            <div className="form-input profile">
              <label className="profile-label" htmlFor="gender">
                Cinsiyeti
              </label>

              <select {...register("gender")}>
                <option value="female">Kadın</option>
                <option value="male">Erkek</option>
              </select>
            </div>

            <div className="form-input phoneNumber">
              <label htmlFor="phone_number">Telefon Numarası</label>
              <Controller
                name="phone_number"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    value={value}
                    international
                    required
                    onChange={onChange}
                    defaultCountry="TR"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="form-input profile">
              <label className="profile-label" htmlFor="first_name">
                Ad
              </label>
              <input {...register("first_name")} placeholder="Ad" />
            </div>
            <div className="form-input profile">
              <label className="profile-label" htmlFor="last_name">
                Soyad
              </label>
              <input {...register("last_name")} placeholder="Soyad" />
            </div>

            <div
              className="profile-checkbox"
              style={{ flexDirection: "column" }}
            >
              <div style={{ width: "100%", display: "flex" }}>
                <input type="checkbox" {...register("get_in_touch")} />
                <label className="profile-label end" htmlFor="get_in_touch">
                  İletişim için bana ulaşılmasını onaylıyorum
                </label>
              </div>
              {errors.get_in_touch && (
                <p style={{ fontSize: "12px", color: "red" }}>
                  İletişimde kalma seçeneği gerekli
                </p>
              )}
            </div>
          </Grid>
        </Grid>
        <div className="profile_generl_profile address">
          <h2>Adres Bilgileri</h2>
        </div>
        <Grid
          className="profilegenerlGrid"
          container
          spacing={{ xs: 1, md: 3 }}
        >
          <Grid item xs={12} sm={12} md={6}>
            <div className="form-input profile">
              <label className="profile-country-label" htmlFor="country">
                Ülke
              </label>
              <Select
                defaultValue={selectedOption}
                onChange={onCountryChange}
                options={countrys}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                  }),
                }}
                menuPosition="fixed"
                maxMenuHeight={180}
                className="customSelectProfile"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="form-input profile">
              <label className="profile-country-label" htmlFor="state_province">
                Şehir
              </label>

              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                  }),
                }}
                defaultValue={selectedOptionStates}
                onChange={onStateChange}
                options={states}
                menuPosition="fixed"
                maxMenuHeight={180}
                className="customSelectProfile"
              />
            </div>
          </Grid>
        </Grid>

        <button
          className="form_submit_btn profile "
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "5px",
            justifyContent: "center",
          }}
          type="submit"
        >
          {btnLoad ? (
            <>
              {" "}
              <CircularProgress
                size={18}
                style={{ color: "white", fontSize: "12px" }}
              />
              "yükleme"
            </>
          ) : (
            "Profili Güncelle"
          )}
        </button>
      </form>
      <form onSubmit={handleSubmit(onSubmitPassword)}>
        <div className="profile_generl_profile address">
          <h2>Parola İşlemleri</h2>
        </div>
        <Grid
          className="profilegenerlGrid"
          container
          spacing={{ xs: 2, md: 3 }}
        >
          <Grid item xs={12} sm={12} md={6}>
            <div className="form-input profile password_main_profile">
              <label className="profile-country-label" htmlFor="old_password">
                Mevcut Parola
              </label>
              <input
                placeholder="Mevcut parola"
                type={showPasswordO ? "text" : "password"}
                {...register("old_password")}
                onKeyUp={handleForShowEyeO}
              />
              {showPasswordEyeO && (
                <button
                  type="button"
                  onClick={togglePasswordVisibilityO}
                  aria-label={showPasswordO ? "Hide password" : "Show password"}
                >
                  {showPasswordO ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </button>
              )}
            </div>

            <div className="form-input profile password_main_profile">
              <label className="profile-country-label" htmlFor="new_password">
                Yeni Parola
              </label>
              <input
                onFocus={() => setMeter(true)}
                onChange={(e) => setNewPassword(e.target.value)}
                type={showPasswordN ? "text" : "password"}
                placeholder="Yeni Parola"
                value={newPassword}
                name="password"
                onKeyUp={handleForShowEyeN}
              />
              {showPasswordEyeN && (
                <button
                  type="button"
                  onClick={togglePasswordVisibilityN}
                  aria-label={showPasswordN ? "Hide password" : "Show password"}
                >
                  {showPasswordN ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </button>
              )}
            </div>
            {meter && (
              <div className="password_validation_for_profile">
                <div className="password-strength-meter"></div>
                <div>
                  <p className="password-strength-meter-para">
                    {!passwordTracker.specialChar &&
                      "En az bir özel karakter içermeli, "}
                  </p>
                  <p className="password-strength-meter-para">
                    {!passwordTracker.number && "En az bir sayı içermeli, "}
                  </p>
                  <p className="password-strength-meter-para">
                    {" "}
                    {!passwordTracker.eightCharsOrGreater &&
                      "En az 12 karakter uzunluğunda olmalı, "}
                  </p>
                </div>
              </div>
            )}
            <div className="form-input profile password_main_profile">
              <label
                className="profile-country-label"
                htmlFor="confirm_password"
              >
                Yeni Parola Tekrar
              </label>
              <input
                type={showPasswordC ? "text" : "password"}
                {...register("confirm_password")}
                placeholder="Yeni Parola Tekrar"
                onKeyUp={handleForShowEyeC}
              />
              {showPasswordEyeC && (
                <button
                  type="button"
                  onClick={togglePasswordVisibilityC}
                  aria-label={showPasswordC ? "Hide password" : "Show password"}
                >
                  {showPasswordC ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </button>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="form-input  profile">
              <label
                className="profile-label-password"
                htmlFor="generate_password"
              >
                Parola Oluşturucu
              </label>
              {/* copy generated password */}
              <div className="password-gererators">
                <span className="password-copy">
                  <input
                    onChange={(e) => checkPasswordCheck(e)}
                    className="password-copy-checkbox"
                    type="checkbox"
                    placeholder="Yeni parola üret"
                  />
                </span>
                {/* show generated password */}
                <input
                  type="text"
                  placeholder="Yeni parola oluştur"
                  defaultValue={gpassword}
                  className="password-gen-text"
                />
                <span
                  className="password-generate-btn"
                  onClick={generatePassword}
                >
                  {gPassLoad ? (
                    <CircularProgress
                      size={18}
                      style={{ color: "white", fontSize: "12px" }}
                    />
                  ) : (
                    "Oluştur"
                  )}
                </span>
                {/* generated password  button*/}
              </div>
            </div>
          </Grid>
        </Grid>
        <button
          className="form_submit_btn profile "
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "5px",
            justifyContent: "center",
          }}
          type="submit"
        >
          {changeBtnLoad ? (
            <>
              {" "}
              <CircularProgress
                size={18}
                style={{ color: "white", fontSize: "12px" }}
              />
              "yükleme"
            </>
          ) : (
            "Parola Güncelle"
          )}
        </button>
      </form>
    </div>
  );
};

export default GeneralInfo;
