import { Container } from "@mui/system";
import React from "react";
import Layout from "../../layout";

const Contact = () => {
  return (
    <Layout>
      <Container>
        <div className="contact-card">
          <div className="contact-float">
            <div className="contact-single">
              <h2>Hakkımızda</h2>
              <p>Sözcü değil, öncü vakıf olmak gayesiyle.</p>
            </div>
            <div className="contact-single contact-middle">
              <h2>Adres</h2>
              <p>
                Mevlanakapı Mahallesi; Mevlanakapı <br /> Caddesi No. 61, 34107
                Fatih/İstanbul
              </p>
            </div>
            <div className="contact-single">
              <h2>İletişim</h2>
              <a href="mailto:ihyavakfi@gmail.com">ihyavakfi@gmail.com</a>
              <a href="tel:(0212) 585 05 02">(0212) 585 05 02</a>
            </div>
          </div>
        </div>
      </Container>

      <div className="contact-map">
        <iframe
          title="ihya map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d345.08387172471566!2d28.925952095607784!3d41.01359811147826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cabbb5f658898b%3A0xfefb92ffe8d51252!2zxLBoeWEgxLBsaW0gSGl6bWV0IHZlIFlhcmTEsW1sYcWfbWEgVmFrZsSx!5e0!3m2!1sen!2sbd!4v1677944265956!5m2!1sen!2sbd"
          width="100%"
          height="550"
          style={{ border: 0 }}
          allowfullScreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </Layout>
  );
};

export default Contact;
