import React, { useContext, useState } from "react";
import { CloseOutlined } from "@mui/icons-material";
import { DonationStore } from "../hooks/donationCart";
import uuid from "react-uuid";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import PropTypes from "prop-types";
import api from "../services/api";
import { useNavigate } from "react-router-dom";
import CartFormCommon from "./common/CartFormCommon";

import { useLoggedInCartContext } from "../hooks/donationCart/loginCart";

const initialData = {
  first_name: "",
  last_name: "",
  phone_number: "",
  email: "",
  group_name: "",
  corporate_name: "",
};

const CartModal = ({
  handleClose,
  dynamicDonationAmount,
  cardData,
  userInfo,
}) => {
  const { dispatch } = useContext(DonationStore);
  const [setUserInfo] = useState({ ...initialData });
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const { setCartItemsCount } = useLoggedInCartContext();

  var cookies = document.cookie
    .split(";")
    .reduce(
      (ac, str) =>
        Object.assign(ac, { [str.split("=")[0].trim()]: str.split("=")[1] }),
      {}
    );

  const handleCart = async (data) => {
    let cartObjForStaticDb = {
      donation_item: data.id,
      amount: data.quantity_price,
    };
    let cartObjForDynamicDb = {
      donation_item: data.id,
      amount: data.dynamic_price,
      quantity_price: data.dynamic_price,
    };
    let userData = data.userData;

    const postCardToCart = async (body) => {
      const headers = {
        headers: {
          Authorization: "Bearer " + cookies.token,
        },
      };
      try {
        let res = await api.post("/api/payment/cart/items/", body, headers);
        setCartItemsCount((prevState) => ++prevState);
        return res;
      } catch (error) {}
    };

    if (data.donation_type === "Static") {
      // Logged in user
      if (cookies.token) {
        await postCardToCart(cartObjForStaticDb);
      }
      //No logged in user
      else {
        dispatch({
          type: "ADD_CART",
          payload: {
            ...data,
            amount:data.quantity_price,
            donation_item: data.id,
            uid: uuid(),
            quantity: 1,
          },
        });
      }

      localStorage.setItem("userInfo", JSON.stringify(userData));

      if (!data.add_to_cart) {
        navigate("/cart");
      }

      handleClose();
    } else if (data.donation_type === "Dynamic" && data.dynamic_price) {

      // Logged in user
      if (cookies.token) {
        await postCardToCart(cartObjForDynamicDb);
      }
      //No logged in user
      else {
        dispatch({
          type: "ADD_CART",
          payload: {
            ...data,
            uid: uuid(),
            donation_item: data.id,
            amount:data.dynamic_price,
            quantity_price: data.dynamic_price,
            quantity: 1,
          },
        });
        setCartItemsCount((prevState) => ++prevState);
      }

      localStorage.setItem("userInfo", JSON.stringify(userData));
      if (!data.add_to_cart) {
        navigate("/cart");
      }
      handleClose();
    } else {
    }
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography variant="div">{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="cartModal">
        <div className="cart_modal_box">
          <div className="cart_modal_box_header">
            <CloseOutlined
              style={{ cursor: "pointer" }}
              onClick={handleClose}
            />
          </div>
          <div className="cart_modal_box_content">
            <h1 className="cart_modal_box_content_title">{cardData.name}</h1>
            <Box sx={{ width: "100%" }}>
              <Box>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  indicatorColor="white"
                  className="cartTab"
                >
                  <Tab
                    className="cartTabBtn"
                    label="Bireysel"
                    {...a11yProps(0)}
                  />
                  <Tab className="cartTabBtn" label="Grup" {...a11yProps(1)} />
                  <Tab
                    className="cartTabBtn"
                    label="Organizasyon"
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box>
              <TabPanel className="tabBoxs" value={value} index={0}>
                <CartFormCommon
                  handleCart={handleCart}
                  setUserInfo={setUserInfo}
                  dynamicDonationAmount={dynamicDonationAmount}
                  cardFormType="personal"
                  cardData={cardData}
                  validation={true}
                  userInfo={userInfo}
                />
              </TabPanel>
              <TabPanel className="tabBoxs" value={value} index={1}>
                <CartFormCommon
                  handleCart={handleCart}
                  setUserInfo={setUserInfo}
                  dynamicDonationAmount={dynamicDonationAmount}
                  cardFormType="group"
                  validation={true}
                  cardData={cardData}
                  userInfo={userInfo}
                />
              </TabPanel>
              <TabPanel className="tabBoxs" value={value} index={2}>
                <CartFormCommon
                  handleCart={handleCart}
                  setUserInfo={setUserInfo}
                  dynamicDonationAmount={dynamicDonationAmount}
                  cardFormType="corporate"
                  cardData={cardData}
                  validation={true}
                  userInfo={userInfo}
                />
              </TabPanel>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartModal;
