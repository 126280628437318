import React, { useEffect, useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../looties/success.json";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const DonationCompleted = () => {
  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);
  const [details, setDetails] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    setDetails(queryParams.get("details"));
    setDescription(queryParams.get("bank_status_code_description"));
  }, []);
  return (
    <div className="completeDonation">
      <Player
        autoplay
        loop
        src={animationData}
        style={{ height: "200px", width: "200px" }}
      ></Player>
      <h3>{details && details}</h3>
      <h5>{description && description}</h5>
      <Button style={{ marginTop: "15px" }} type="praimary">
        <Link to="/">Anasayfaya Dön</Link>
      </Button>
    </div>
  );
};

export default DonationCompleted;
