import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import Providers from "../components/Providers";
import { ProtectRoutes } from "../hooks/protectedRoutes";
import Profile from "../pages/profile/Profile";
import Home from "../pages/home/Home";
import Bank from "../pages/bank/Bank";
import Cart from "../pages/cart/Cart";
import Contact from "../pages/contact";
import AboutUs from "../pages/about-us";
import ForgotPassword from "../pages/login-signup/ForgotPassword";
import Error from "../components/Error";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<Error />} path="/" element={<Providers />}>
      <Route index element={<Home />} />
      <Route path="/bank" element={<Bank />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/profile" element={<ProtectRoutes />}>
        <Route index element={<Profile />} />
      </Route>
      <Route path="/forgot-password" element={<ForgotPassword />} />
    </Route>
  )
);
