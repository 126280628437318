import { Container } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";

const Notice = () => {
  return <div className="notice_area">
    <Container>
      <Link className="noticeText" to="/bank"><p>Banka hesap numaralarımıza ulaşmak için tıklayınız.</p></Link>
    </Container>
  </div>;
};

export default Notice;
