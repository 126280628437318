import React, { useEffect, useState } from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Controller, useForm } from "react-hook-form";
import api from "../../services/api";
import { Hidden } from "@mui/material";
import profile from "../../assets/loginProfile.svg";
import { toast } from "react-toastify";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Select from "react-select";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

let isChangedCountry = false;
const Signup = ({ setModal, setOpenLogin, setOpenSignup }) => {
  const [error, setError] = useState("");
  const [countrys, setCountrys] = useState([]);
  const [states, setStates] = useState([]);
  const [meter, setMeter] = useState(false);
  const [password, setPassword] = useState("");
  const [selectedOption, setSelectedOption] = useState({
    value: 226,
    label: "Turkey",
  });

  const [selectedStates, setSelectedStates] = useState({
    value: 3433,
    label: "Adana",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordCon, setShowPasswordCon] = useState(false);
  const [showPasswordEyeRe, setShowPasswordEyeRe] = useState(false);
  const [showPasswordEyeCon, setShowPasswordEyeCon] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibilityCon = () => {
    setShowPasswordCon(!showPasswordCon);
  };

  const handleForShowEyeRe = (e) => {
    if (!e.target.value) {
      setShowPasswordEyeRe(false);
    } else {
      setShowPasswordEyeRe(true);
    }
  };
  const handleForShowEyeCon = (e) => {
    if (!e.target.value) {
      setShowPasswordEyeCon(false);
    } else {
      setShowPasswordEyeCon(true);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  useEffect(() => {
    fetchCountry();
    let defaultValues = {};
    reset({ ...defaultValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onCountryChange = (newValue, actionMeta) => {
    setSelectedOption({ ...newValue });
    isChangedCountry = true;
  };

  const onStateChange = (newValue, actionMeta) => {
    setSelectedStates({ ...newValue });
  };
  const onSubmit = async (value) => {
    setError("");
    if (password !== value.confirm_new_password) {
      toast.error(`Password and confirm password should be same`);
    } else {
      let body = {
        phone_number: value.phone_number,
        password: password,
        confirm_new_password: value.confirm_new_password,
        username: value.email,
        email: value.email,
        country: selectedOption.value,
        state_province: selectedStates.value,
      };
      try {
        const { data } = await api.post("/api/management/users/", body);
        toast.success(`${data.details}`);
        setModal();
      } catch ({ response }) {
        const { data } = response;
        if (data.details) {
          toast.error(`${data.details}`);
        }
        if (data.non_field_errors) {
          toast.error(`${data.non_field_errors[0]}`);
        }
      }
    }
  };

  const fetchCountry = async () => {
    try {
      let resCountry = await api.get("/api/management/countries/");
      if (resCountry) {
        let countrys = resCountry.data.map((e) => {
          return {
            value: e.id,
            label: e.name,
          };
        });

        setCountrys(countrys);
      }
    } catch (error) {
      toast.error(`${error.message}`);
    }
  };

  useEffect(() => {
    const handleCountry = async (id) => {
      try {
        let { data } = await api.get(`/api/management/countries/${id}`);

        if (data) {
          let states = data.state_provinces.map((e) => {
            return {
              value: e.id,
              label: e.name,
            };
          });
          if (isChangedCountry) {
            setSelectedStates({
              value: data?.state_provinces[0]?.id,
              label: data?.state_provinces[0]?.name,
            });
            isChangedCountry = false;
          }

          setStates(states);
        }
      } catch (error) {}
    };
    handleCountry(selectedOption.value);
  }, [selectedOption]);

  const handleChangeSignup = () => {
    setOpenLogin(true);
    setOpenSignup(false);
  };

  const atLeastOneNumeric = /[0-9]/g; // numbers from 0 to 9
  const atLeastOneSpecialChar = /[#?!@$%^&*-]/g; // any of the special characters within the square brackets
  const eightCharsOrMore = /.{12,}/g; // eight characters or more

  const passwordTracker = {
    number: password.match(atLeastOneNumeric),
    specialChar: password.match(atLeastOneSpecialChar),
    eightCharsOrGreater: password.match(eightCharsOrMore),
  };

  return (
    <>
      <div className="signup_card main">
        <Hidden smDown>
          <div className="signup_card_image">
            <div className="overlay">
              <h4>Zaten Üye misin?</h4>
              <button className="form_btn" onClick={handleChangeSignup}>
                {" "}
                Giriş Yap <ArrowRightAltIcon className="btnicon" />{" "}
              </button>
            </div>
          </div>
        </Hidden>
        <div className="form_area">
          <div className="form-heading">
            <img src={profile} alt="Profile Icon" className="login-profile" />
            <h2>Kayıt Ol</h2>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* email */}
            <div className="form-input">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                {...register("email", { required: true })}
                placeholder="ornek@mail.com"
              />
              {errors.email && <p>Email gerekli.</p>}
            </div>
            {/*mobile */}
            <div className="form-input phoneNumber signUp_phone">
              <label htmlFor="phone_number">Telefon Numarası</label>
              <Controller
                name="phone_number"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    value={value}
                    international
                    required
                    onChange={onChange}
                    defaultCountry="TR"
                    limitMaxLength={10}
                  />
                )}
              />
            </div>

            <div className="form-input-double">
              <div className="form-input double password_main">
                <label htmlFor="password">Parola</label>
                <input
                  onFocus={() => setMeter(true)}
                  onChange={(e) => setPassword(e.target.value)}
                  type={showPassword ? "text" : "password"}
                  placeholder="********"
                  value={password}
                  name="password"
                  onKeyUp={handleForShowEyeRe}
                />
                {showPasswordEyeRe && (
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    aria-label={
                      showPassword ? "Hide password" : "Show password"
                    }
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </button>
                )}

                {meter && (
                  <div>
                    <div className="password-strength-meter"></div>
                    <div>
                      <p className="password-strength-meter-para">
                        {!passwordTracker.specialChar &&
                          "En az bir özel karakter içermeli,"}
                      </p>
                      <p className="password-strength-meter-para">
                        {!passwordTracker.number &&
                          "En az bir sayı içermeli "}
                      </p>
                      <p className="password-strength-meter-para">
                        {" "}
                        {!passwordTracker.eightCharsOrGreater &&
                          "En az 12 karakter uzunluğunda olmalıdır"}
                      </p>
                    </div>
                  </div>
                )}
              </div>

              {/* confirm password */}
              <div className="form-input double password_main">
                <label htmlFor="confirm_new_password">Parola Tekrar</label>
                <input
                  type={showPasswordCon ? "text" : "password"}
                  {...register("confirm_new_password", { required: true })}
                  placeholder="********"
                  onKeyUp={handleForShowEyeCon}
                />
                {errors.confirm_new_password && (
                  <p>Parola tekrarı gerekli.</p>
                )}
                {showPasswordEyeCon && (
                  <button
                    type="button"
                    onClick={togglePasswordVisibilityCon}
                    aria-label={
                      showPasswordCon ? "Hide password" : "Show password"
                    }
                  >
                    {showPasswordCon ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </button>
                )}
              </div>
            </div>

            <div className="form-input-double">
              <div className="form-input double">
                <label className="signup-country-label" htmlFor="country">
                  Ülke
                </label>
                <Select
                  defaultValue={selectedOption}
                  onChange={onCountryChange}
                  options={countrys}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                    }),
                  }}
                  menuPosition="fixed"
                  maxMenuHeight={180}
                  className="customSelect"
                  required
                />
              </div>
              <div className="form-input double">
                <label className="signup-country-label" htmlFor="states">
                  Şehir
                </label>

                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                    }),
                  }}
                  value={selectedStates}
                  onChange={onStateChange}
                  options={states}
                  menuPosition="fixed"
                  maxMenuHeight={180}
                  className="customSelect"
                  required
                />

                {errors.states && <p>Şehir gerekli.</p>}
              </div>
            </div>

            <button className="form_submit_btn" type="submit">
              {" "}
              KAYIT OL{" "}
            </button>
          </form>
          <p className="formError">{error}</p>
        </div>
      </div>
     
    </>
  );
};

export default Signup;
