import React from "react";
import { createRoot } from "react-dom/client";

import { RouterProvider } from "react-router-dom";

import "./app.css";
// import "./components/component.css";
import reportWebVitals from "./reportWebVitals";
import { router } from "./router/routes";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(<RouterProvider router={router} />);

reportWebVitals();
