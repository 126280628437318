import React, { useContext, useState } from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Checkbox, FormControlLabel, Hidden } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useAuth } from "../../hooks/auth";
import { Link } from "react-router-dom";
import { CurrentUserStore } from "../../hooks/auth/currentUser";
import api from "../../services/api";
import profile from "../../assets/loginProfile.svg";
import PhoneInput from "react-phone-number-input";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
const Login = ({ setModal, popup, setOpenLogin, setOpenSignup }) => {
  const { login } = useAuth();
  const { userDispatch } = useContext(CurrentUserStore);
  const [loginWith, setLoginWith] = useState("Login with email");

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordEye, setShowPasswordEye] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleForShowEye = (e) => {
    if (!e.target.value) {
      setShowPasswordEye(false);
    } else {
      setShowPasswordEye(true);
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm();

  const onSubmit = async (data) => {
    const { username, password, phone_number } = data;
    try {
      let {
        data: { token },
      } = await login({ username, phone_number, password });

      userDispatch({
        type: "ADD_TOKEN",
        payload: token,
      });
      if (token) {
        toast.success("Successfully login");

        const fetchData = async () => {
          const headers = {
            headers: {
              Authorization: "Bearer " + token,
            },
          };
          try {
            let { data } = await api.get("/api/management/users/me", headers);
            userDispatch({
              type: "ADD_USER",
              payload: data,
            });
          } catch (error) {}
        };
        fetchData();
        // window.location.reload()
        setModal();
      }
    } catch (error) {
      toast.error(`${error.response.data.details}`);
    }
  };

  const handleChangeLogin = () => {
    setOpenLogin(false);
    setOpenSignup(true);
  };

  const loginWithEmailPhone = () => {
    if (loginWith === "Login with email") {
      setLoginWith("Login with phone number");
      setValue("username", "");
    } else if (loginWith === "Login with email") {
      setLoginWith("Login with phone number");
    } else {
      setLoginWith("Login with email");
    }
  };

  return (
    <div
      className="signup_card login_card"
      style={!popup ? { margin: "15% auto" } : { margin: "0 auto" }}
    >
      <Hidden smDown>
        <div className="signup_card_image">
          <div className="overlay">
            <h4>Henüz Üye Değil misiniz?</h4>

            <button className="form_btn" onClick={handleChangeLogin}>
              {" "}
              Kayıt Ol <ArrowRightAltIcon className="btnicon" />{" "}
            </button>
          </div>
        </div>
      </Hidden>
      <div className="form_area">
        <div className="form-heading">
          <img src={profile} alt="Profile Icon" className="login-profile" />
          <h2>Giriş Yap</h2>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {loginWith === "Login with phone number" ? (
            <div className="form-input phoneNumber">
              <label htmlFor="phone_number">Telefon Numarası</label>
              <Controller
                name="phone_number"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    value={value}
                    international
                    required
                    onChange={onChange}
                    defaultCountry="TR"
                    limitMaxLength={10}
                  />
                )}
              />
              {errors.phone_number && <p>Telefon numarası gerekli.</p>}
            </div>
          ) : loginWith === "Login with email" ? (
            <div className="form-input">
              <label htmlFor="username">Email</label>
              <input
                {...register("username", { required: true })}
                placeholder="Email"
              />
              {errors.username && <p>Email gerekli.</p>}
            </div>
          ) : (
            <div className="form-input">
              <label htmlFor="username">Telefon Numarası</label>
              <input
                {...register("username", { required: true })}
                placeholder=""
              />
              {errors.username && <p>Telefon numarası gerekli.</p>}
            </div>
          )}

          {/* <div className="form-input">
            <label htmlFor="username">Kullanıcı adı</label>
            <input
              {...register("username", { required: true })}
              placeholder="Şifrenizi Girin"
            />
            {errors.username && <p>Mobile number is required.</p>}
          </div> */}
          <div className="form-input password_main">
            <label htmlFor="password">Parola</label>
            <input
              type={showPassword ? "text" : "password"}
              {...register("password", { required: true })}
              placeholder="********"
              onKeyUp={handleForShowEye}
            />

            {errors.password && <p>Parola gerekli.</p>}
            {showPasswordEye && (
              <button
                type="button"
                onClick={togglePasswordVisibility}
                aria-label={showPassword ? "Hide password" : "Show password"}
              >
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </button>
            )}
          </div>
          <div className="form-checkbox">
            <Controller
              name="checkbox"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  value="end"
                  control={<Checkbox {...field} />}
                  label="Bilgilerimi Hatırla"
                  labelPlacement="end"
                />
              )}
            />
          </div>

          <p
            style={{
              textAlign: "center",
              margin: "10px 0",
              cursor: "pointer",
              fontSize: "13px",
              textDecoration: "underline",
            }}
            onClick={loginWithEmailPhone}
          >
            {loginWith === "Login with email"
              ? "Telefon numarası ile giriş"
              : "Email ile giriş"}
          </p>

          <button className="form_submit_btn" type="submit">
            {" "}
            Giriş{" "}
          </button>
          <hr />
          <Link to="/forgot-password">
            <span style={{ marginTop: "10px", cursor: "pointer" }}>
              Parolamı Unuttum
            </span>
          </Link>
        </form>
      </div>
    </div>
  );
};

export default Login;
