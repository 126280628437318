import { createContext, useReducer } from "react";
const DonationStore = createContext();
var cookies = document.cookie
  .split(";")
  .reduce(
    (ac, str) =>
      Object.assign(ac, { [str.split("=")[0].trim()]: str.split("=")[1] }),
    {}
  );

// cart reducer start
const initvalue = {
  cartItems: localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems"))
    : [],
  steps: "",
  token: cookies,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_CART":
      const newItem = action.payload;
      // const existingItem = state.cartItems.find((item) => item.id == newItem.id);
      const cartItems = [...state.cartItems, newItem];
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
      return {
        ...state,
        cartItems,
      };

    case "INCREASE":
      state.cartItems[
        state.cartItems.findIndex((item) => item.uid === action.payload.uid)
      ].quantity++;
      return {
        ...state,
        cartItems: [...state.cartItems],
      };
    case "DECREASE":
      state.cartItems[
        state.cartItems.findIndex((item) => item.uid === action.payload.uid)
      ].quantity--;
      return {
        ...state,
        cartItems: [...state.cartItems],
      };

    //  remove individual product from cart
    case "REMOVE_ITEM":
      return {
        ...state,
        cartItems: [
          ...state.cartItems.filter((item) => item.uid !== action.payload.uid),
        ],
      };
    case "CLEAR_CART": {
      return { ...state, cartItems: [] };
    }
    case "NEXT": {
      return { ...state, step: action.payload };
    }
    default:
      return state;
  }
};
// cart reducer end

///////////////////////////////////////////////////////////////////////////////////////

const StoreProvide = (props) => {
  const [state, dispatch] = useReducer(reducer, initvalue);
  const value = { state, dispatch };

  return (
    <DonationStore.Provider value={value}>
      {props.children}{" "}
    </DonationStore.Provider>
  );
};

export { DonationStore, StoreProvide };
