import { Backdrop, CircularProgress, TextField } from "@mui/material";
import { Container } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { EventStore } from "../../hooks/events";
import Layout from "../../layout";
import api from "../../services/api";

function ForgotPassword() {
  const [setOpen] = useState(false);
  const { openLogin, setOpenLogin } = useContext(EventStore);
  const [username, setUsername] = useState("");
  const [openBackdrop, setBackdrop] = React.useState(false);
  useEffect(() => {
    setOpenLogin(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openLogin]);

  const forgotPassword = async () => {
    let body = {
      username,
    };

    setBackdrop(true);
    await api
      .post("api/management/forgot-password/", body)
      .then((res) => {
        toast.success(`${res.data.details}`);
        setUsername(" ");
        setBackdrop(false);
        setOpen(false);
        
      })
      .catch((err) => {
        toast.error(`${err.response.data.details}`);
        setBackdrop(false);
      });
  };
  return (
    <Layout>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container>
        <div className="forgot-password">
          <h2>Parola Sıfırlama Formu</h2>
          <TextField
            autoFocus
            margin="dense"
            id="username"
            label="Mail adresinizi veya telefon numaranızı giriniz"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setUsername(e.target.value)}
          />
          <button className="btn" onClick={forgotPassword}>
            Parola Sıfırlama Formu Gönder
          </button>
          {/* <span className="muted-text forgot-password-first">Sisteme kayıtlı mail adresiniz veya telefon numaranızı kullanabilirsiniz.</span> */}
          <span className="muted-text forgot-password-first">Email adresi formatı: ornek@mail.com</span>
          <span className="muted-text forgot-password-first">Telefon numarası formatı: +905555555555</span>
        </div>
      </Container>
    </Layout>
  );
}

export default ForgotPassword;
