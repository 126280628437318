import { CardMedia, Grid } from "@mui/material";
import React from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import CartItemCard from "./common/CartItemCard";

const MyDonationBasket = ({
  handleNext,
  cartItems,
  token,
  cartLoader,
  handleDuplicate,
  handleDelete,
  handleDeleteAll,
}) => {
  const reduceCallback = (group, product) => {
    const { donation_item } = product;
    const { amount } = product;
    group[donation_item] = group[donation_item] ?? [];
    group[donation_item][amount] = group[donation_item][amount] ?? [];
    group[donation_item][amount].push(product);
    return group;
  };

  const groupByItems = cartItems.reduce(reduceCallback, {});

  const total = () => {
    const cartFromStorage = JSON.parse(localStorage.getItem("cartItems"));
    if (token) {
      return cartItems?.reduce((p, c) => p + Number(c.amount), 0);
    } else {
      return cartFromStorage?.reduce(
        (p, c) =>
          c.quantity_price
            ? p + Number(c.quantity_price)
            : p + Number(c.amount),
        0.0
      );
    }
  };

  if (cartLoader) {
    return <>Loading cart data...</>;
  } else {
    return (
      <>
        <div className="cart-header-part">
          <div className="cart-header-left">
            <h3>
              {" "}
              <ShoppingBagIcon />
              Bağış sepetim
            </h3>
          </div>
          <div className="cart-header-right" onClick={handleDeleteAll}>
            <h4>
              Sepeti Temizle <DeleteOutlineIcon />
            </h4>
          </div>
        </div>
        <Grid container rowSpacing={2}>
          <Grid item xs={12} sm={12} md={8}>
            <div className="all-items-card-cart">
              {cartItems?.length > 0 ? (
                Object.entries(groupByItems)?.map((Items) => {
                  return Object.entries(Items?.[1])?.map((item, key) => (
                    <CartItemCard
                      key={key}
                      data={item?.[1]?.[0]}
                      counter={item[1]?.length}
                      lamount={true}
                      incdec={true}
                      handleDuplicate={handleDuplicate}
                      handleDelete={handleDelete}
                      token={token}
                    />
                  ));
                })
              ) : (
                <>
                  <div className="empty">
                    <CardMedia
                      component="img"
                      height="100%"
                      image="/empty.png"
                      title="image"
                    />
                    <p>Sepet boş!</p>
                    <h6>Devam etmek için sepete ekleyin.</h6>
                  </div>
                </>
              )}
              {/* <Skeleton /> */}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            {/* <div className="intotal">
              <h3>Toplam Bağış: {total()}</h3>
              <button className="continueBtn" onClick={handleNext}>
                DEVAM ET
              </button>
            </div> */}
            <div className="donation_payment_info_total">
              <h1 className="intotalHeading">Bağış Sepeti</h1>
              <div className="intotalDonation">
                Bağış : <br />{" "}
                <span className="mainPrice">
                  <span className="liraSymbol">₺</span> {total()}.00
                </span>
              </div>
              <div className="intotalBtns">
                <button className="continueBtn" onClick={handleNext}>
                  DEVAM ET
                </button>
              </div>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
  // return <div>Hello</div>;
};

export default MyDonationBasket;
