import {
  Box,
  CardMedia,
  Dialog,
  Hidden,
  IconButton,
  InputAdornment,
  OutlinedInput,
  SwipeableDrawer,
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DonationStore } from "../hooks/donationCart";
import Login from "../pages/login-signup/Login";
import Signup from "../pages/login-signup/Signup";
import CartItemCard from "../components/common/CartItemCard";
import LoginIcon from "@mui/icons-material/Login";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import MenuOpenOutlinedIcon from "@mui/icons-material/MenuOpenOutlined";
import { useAuth } from "../hooks/auth";
import { EventStore } from "../hooks/events";
import api from "../services/api";
import CloseIcon from "@mui/icons-material/Close";
import userAvatar from "../assets/avatar.svg";
import cartBasket from "../assets/cartBasket.svg";
import SearchIcon from "@mui/icons-material/Search";
import { CurrentUserStore } from "../hooks/auth/currentUser";
import { ToastContainer } from "react-toastify";
import { useLoggedInCartContext } from "../hooks/donationCart/loginCart";
import { nanoid } from "nanoid";

const Header = () => {
  const { openLogin, setOpenLogin } = useContext(EventStore);
  const { userState, userDispatch } = useContext(CurrentUserStore);
  const [openSignup, setOpenSignup] = useState(false);
  const { state, dispatch } = useContext(DonationStore);

  const [anchorEl, setAnchorEl] = useState("close");
  const [userDropdown, setUserDropDown] = useState("close");
  const [loginName, setLoginName] = useState("");
  const [cartItems, setCartItems] = useState([]);
  const { logout, cookies } = useAuth();

  const { cartItemsCount, setCartItemsCount } = useLoggedInCartContext();

  const navigate = useNavigate();
  const handleClickOpen = () => {
    setOpenLogin(true);
  };
  const handleClose = () => {
    setOpenLogin(false);
    setAnchorEl(false);
    setOpenSignup(false);
  };
  const handleUserDropdown = () => {
    if (userDropdown === "close") {
      setUserDropDown("show");
      setAnchorEl("close");
    } else if (userDropdown === "show") {
      setUserDropDown("close");
    } else {
      setUserDropDown("close");
    }
  };
  const handleClick = () => {
    if (anchorEl === "close") {
      setAnchorEl("show");
      setUserDropDown("close");
    } else if (anchorEl === "show") {
      setAnchorEl("close");
    } else {
      setAnchorEl("close");
    }
  };
  const handleClickSignup = () => {
    setOpenSignup(true);
  };
  const handleProfile = () => {
    if (state?.token) {
      navigate("/profile");
    } else {
      navigate("/");
    }
  };
  const ref = useRef();
  const userRef = useRef();

  const reduceCallback = (group, product) => {
    const { donation_item } = product;
    const { amount } = product;
    group[donation_item] = group[donation_item] ?? [];
    group[donation_item][amount] = group[donation_item][amount] ?? [];
    group[donation_item][amount].push(product);
    return group;
  };

  const groupByItems = cartItems.reduce(reduceCallback, {});

  const handleDuplicate = async (data) => {
    let body = {
      donation_item: data.donation_item,
      amount: data.quantity_price ? data.quantity_price : data.amount,
    };
    if (userState.token) {
      const headers = {
        headers: {
          Authorization: "Bearer " + userState.token,
        },
      };
      try {
        let res = await api.post("/api/payment/cart/items/", body, headers);

        if (res) {
          let { data } = await api.get("/api/donor/items/");
          const item2 = data.find(
            (item2) => item2.id === res.data.donation_item
          );

          let newObj = {
            ...item2,
            ...res.data,
            donationId: res.data.id,
          };
          setCartItems([...cartItems, newObj]);
          setCartItemsCount((prevState) => ++prevState);
        }
        return res;
      } catch (error) {}
    } else {
      const id = nanoid();

      dispatch({
        type: "ADD_CART",
        payload: {
          ...data,
          donation_item: data.id,
          amount: data.quantity_price ? data.quantity_price : data.amount,
          uid: id,
          quantity: 1,
        },
      });
    }
  };

  const handleDelete = async (data) => {
    if (userState.token) {
      const filterData = cartItems.filter(
        (e) => e.donationId !== data.donationId
      );

      setCartItems(filterData);

      const headers = {
        headers: {
          Authorization: "Bearer " + userState.token,
        },
      };
      try {
        await api.delete(`/api/payment/cart/items/${data.donationId}`, headers);
        setCartItemsCount((prevState) => --prevState);
      } catch (error) {}
    } else {
      const cartItemsForStorage = JSON.parse(localStorage.getItem("cartItems"));
      const storeDlt = cartItemsForStorage.filter((e) => e.uid !== data.uid);
      localStorage.setItem("cartItems", JSON.stringify(storeDlt));
      dispatch({ type: "REMOVE_ITEM", payload: data });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setAnchorEl("close");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    const handleClickOutLogin = (event) => {
      if (!userRef?.current?.contains(event.target)) {
        setUserDropDown("close");
      }
    };
    document.addEventListener("mousedown", handleClickOutLogin);

    return () => {
      document.removeEventListener("mousedown", handleClickOutLogin);
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, userRef]);

  useEffect(() => {
    if (userState.token) {
      const fetchData = async () => {
        const headers = {
          headers: {
            Authorization: "Bearer " + userState.token,
          },
        };
        try {
          let { data } = await api.get("/api/payment/cart/", headers);
          let cartItem = data;
          if (data) {
            let { data } = await api.get("/api/donor/items/");
            let allItems = data;
            const mergedArr = cartItem.cart_items?.map((item1) => {
              const item2 = allItems.find(
                (item2) => item2.id === item1.donation_item
              );
              return { ...item1, ...item2 };
            });
            setCartItems(mergedArr);
          }
        } catch (error) {}
      };
      fetchData();
    } else {
      setCartItems(state.cartItems);
    }
    // TODO: Fix dependency.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.cartItems.length, cartItemsCount]);

  useEffect(() => {
    (async () => {
      // Merge Start

      if (userState.token) {
        const headers = {
          headers: {
            Authorization: "Bearer " + userState.token,
          },
        };
        // setCartLoader(true);
        let { data } = await api.get("/api/payment/cart/", headers);
        let cartItem = data;

        if (data) {
          let { data } = await api.get("/api/donor/items/");
          let allItems = data;
          const mergedArr = cartItem.cart_items?.map((item1) => {
            const item2 = allItems.find(
              (item2) => item2.id === item1.donation_item
            );
            return { ...item1, ...item2, donationId: item1.id };
          });

          // setCartLoader(false);
          setCartItems(mergedArr);
        } else {
          setCartItems(state.cartItems);
        }
      }
    })();

    // Merge End
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItemsCount]);

  useEffect(() => {
    const fetchData = async () => {
      const headers = {
        headers: {
          Authorization: "Bearer " + userState.token,
        },
      };
      try {
        let { data } = await api.get("/api/management/users/me", headers);
        setLoginName(data.username);
      } catch (error) {}
    };
    fetchData();
  }, [userState.token]);

  /////////////// responsive header /////////////////////
  const [statep, setStatep] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setStatep({ ...state, [anchor]: open });
  };
  // nav main part

  const handleLogout = async () => {
    try {
      userDispatch({
        type: "REMOVE_TOKEN",
      });
      await logout();
    } catch (error) {}
  };

  return (
    <>
      <div className="header_area">
        <Container>
          <div className="header_area__items">
            <Hidden mdDown>
              <div className="header_area__contact">
                <Link to="/about-us">Biz kimiz</Link>
                <Link to="/contact">İletişim</Link>
                <a href="https://ihyavakfi.org.tr/" target={"_blank"}>
                  İhya Vakfı
                </a>
              </div>
            </Hidden>
            <div className="header_area__logo">
              <Link to="/">
                {" "}
                <img src="/logo.svg" alt="logo" />
              </Link>
            </div>
            <div className="header_cart_search_ac">
              {/* profile */}
              <Hidden mdDown>
                <span
                  onClick={handleUserDropdown}
                  className="header-icon header-profile-icon"
                >
                  <img src={userAvatar} alt="userAvatar" />
                  {
                    // userState.token ? loginName : "Giriş Yap"
                  }
                  {/* Login/Register */}
                  {cookies?.token && loginName}
                  {!cookies?.token && "Giriş Yap"}
                </span>
                <div ref={userRef} className={`userDropdown ${userDropdown}`}>
                  {cookies.token ? (
                    <div>
                      <span onClick={handleProfile}>
                        <PermContactCalendarIcon /> Hesabım
                      </span>
                      <span onClick={() => handleLogout()}>
                        <LoginIcon /> Çıkış Yap
                      </span>
                    </div>
                  ) : (
                    <div>
                      <span onClick={handleClickOpen}>
                        {" "}
                        <LockOpenIcon /> Giriş Yap
                      </span>
                      <span onClick={handleClickSignup}>
                        {" "}
                        <HowToRegIcon /> Kayıt Ol
                      </span>
                    </div>
                  )}
                </div>
              </Hidden>

              {/* donate */}
              <Hidden mdDown>
                <button className="bonate-btn">Bağış yap</button>
              </Hidden>
              {/* cart */}
              <span onClick={handleClick} className="header-icon header-basket">
                {" "}
                <img src={cartBasket} alt="cartBasket" />
                <p className="cartCount">{cartItems.length}</p>
              </span>
              <div ref={ref} className={`cartDropdown ${anchorEl}`}>
                <div className="cartScroll">
                  {cartItems.length > 0 ? (
                    Object.entries(groupByItems)?.map((Items) => {
                      return Object.entries(Items?.[1])?.map((item, key) => (
                        <CartItemCard
                          shortCard={true}
                          lamount={true}
                          key={key}
                          data={item?.[1]?.[0]}
                          counter={item[1]?.length}
                          incdec={false}
                          handleDuplicate={handleDuplicate}
                          handleDelete={handleDelete}
                        />
                      ));
                    })
                  ) : (
                    <div className="empty">
                      <CardMedia
                        component="img"
                        height="100%"
                        image="/empty.png"
                        title="image"
                      />
                      <p>Sepetiniz Boş!</p>
                      <h6>Bağışlamak için lütfen sepete ekleyin.</h6>
                    </div>
                  )}
                </div>
                {cartItems.length > 0 ? (
                  <div className="cartItemFooter">
                    <Link to="/cart">
                      <button>Sepete Git</button>{" "}
                    </Link>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <Hidden mdUp>
                <div className="mobileBar" onClick={toggleDrawer("top", true)}>
                  <MenuOpenOutlinedIcon />
                </div>
                <React.Fragment key={"top"}>
                  <SwipeableDrawer
                    anchor={"top"}
                    open={statep.top}
                    onClose={toggleDrawer(statep.top, false)}
                    onOpen={toggleDrawer(statep.top, true)}
                    className="mbl-nav-bar"
                  >
                    <Box role="presentation">
                      <div className="mbl-nav-header">
                        <div className="mbl-nav-logo">
                          <img src="/logo.svg" alt="" />
                        </div>
                        <CloseIcon onClick={toggleDrawer("top", false)} />
                      </div>
                      {/* <div className="mbl-nav-src">
                        <OutlinedInput
                          className="mbl-nav-src-input"
                          placeholder="Arama"
                          endAdornment={
                            <InputAdornment position="start">
                              <IconButton edge="end" className="iconBtn">
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </div> */}
                      <div className="mbl-nav-main">
                        {!cookies.token && (
                          <>
                            <p
                              className="nav-accor-items"
                              onClick={handleClickOpen}
                            >
                              {" "}
                              <LockOpenIcon /> Giriş Yap
                            </p>
                            <p
                              className="nav-accor-items"
                              onClick={handleClickSignup}
                            >
                              {" "}
                              <HowToRegIcon />
                              Kayıt Ol
                            </p>
                          </>
                        )}

                        {cookies.token && (
                          <>
                            <p
                              className="nav-accor-items"
                              onClick={handleProfile}
                            >
                              <PermContactCalendarIcon /> Hesabım
                            </p>
                            <p
                              className="nav-accor-items"
                              onClick={() => handleLogout()}
                            >
                              <LoginIcon /> Çıkış Yap
                            </p>
                          </>
                        )}

                        <Link to="/about-us">
                          <p className="nav-accor-items about">Biz kimiz</p>
                        </Link>
                        <Link to="/contact">
                          <p className="nav-accor-items contact">İletişim</p>
                        </Link>
                      </div>
                    </Box>
                  </SwipeableDrawer>
                </React.Fragment>
              </Hidden>
            </div>
          </div>
        </Container>
        <ToastContainer
          position="bottom-center"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
      <Dialog
        open={openLogin}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"50%"}
        className="form-box-login"
      >
        {openLogin && (
          <Login
            popup={true}
            setOpenSignup={setOpenSignup}
            setOpenLogin={setOpenLogin}
            setModal={handleClose}
          />
        )}
      </Dialog>
      <Dialog
        open={openSignup}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"50%"}
        className="form-box-signup"
      >
        {openSignup && (
          <Signup
            popup={true}
            openLogin={openLogin}
            setOpenSignup={setOpenSignup}
            setOpenLogin={setOpenLogin}
            setModal={handleClose}
          />
        )}
      </Dialog>
    </>
  );
};

export default Header;
