import {
  Backdrop,
  CardMedia,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useState, useEffect } from "react";
import Layout from "../../layout";
import PropTypes from "prop-types";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PersonIcon from "@mui/icons-material/Person";

import GeneralInfo from "../../components/GeneralInfo";
import MyDonation from "../../components/MyDonation";
import api from "../../services/api";
import profileIcon from "../../assets/logged-profile.svg";
import { LogoutOutlined } from "@mui/icons-material";
import { useAuth } from "../../hooks/auth";

const Profile = () => {
  const [value, setValue] = useState(0);
  var cookies = document.cookie
    .split(";")
    .reduce(
      (ac, str) =>
        Object.assign(ac, { [str.split("=")[0].trim()]: str.split("=")[1] }),
      {}
    );
  const [data, setData] = useState("");
  const [myDonation, setMyDonation] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let { logout } = useAuth();
  let handleLogOut = async () => {
    try {
      await logout();
    } catch (error) {}
  };

  useEffect(() => {
    setOpen(!open);
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    const headers = {
      headers: {
        Authorization: "Bearer " + cookies.token,
      },
    };

    try {
      let { data } = await api.get("/api/management/users/me", headers);
      if (data) {
        let resCountry = await api.get("/api/management/countries/");
        let provienceResponse = await api.get(
          `/api/management/countries/${data.country}/`
        );
        let country = resCountry?.data?.find((e) => {
          return e.id === data.country;
        });
        let state = provienceResponse?.data?.state_provinces?.find((e) => {
          return e.id === data.state_province;
        });

        setData({ ...data, country, state });
        setOpen(false);
      }
    } catch (error) {
      setOpen(false);
    }
  };

  const fetchMydonation = async (url) => {
    const headers = {
      headers: {
        Authorization: "Bearer " + cookies.token,
      },
    };
    try {
      let { data } = await api.get(url, headers);
      if (data) {
        setMyDonation(data);
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchMydonation("/api/payment/transactions/?page=1");
  }, []);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography variant="div">{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  const handleClose = () => {
    setOpen(false);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage, donation) => {
    let paginated_page;
    let generated_fetch_url;

    if (newPage > page) {
      paginated_page = donation.next
        ? parseInt(donation.next.split("page=")[1])
        : 1;
    } else {
      paginated_page = donation.previous
        ? parseInt(donation.previous.split("page=")[1])
        : 1;
    }

    paginated_page = isNaN(paginated_page) ? 1 : paginated_page;

    generated_fetch_url = "/api/payment/transactions/?page=" + paginated_page;

    fetchMydonation(generated_fetch_url);
    setPage(paginated_page - 1);
  };

  const handleChangeRowsPerPage = (event, donation) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Layout>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <div className="profile_left_tabs">
              <Box
                sx={{
                  bgcolor: "background.paper",
                  display: "flex",
                  height: 224,
                }}
              >
                <Grid container>
                  <Grid item xs={12} sm={4} md={3}>
                    <div className="profile_left_card">
                      <CardMedia
                        component="img"
                        image={profileIcon}
                        title="image"
                      />
                      <h4>{data?.username}</h4>
                    </div>
                    <Tabs
                      className="profileTab"
                      orientation="vertical"
                      variant="scrollable"
                      value={value}
                      onChange={handleChange}
                      aria-label="Vertical tabs example"
                      sx={{ borderRight: 1, borderColor: "divider" }}
                    >
                      <Tab
                        className="profileTabTitle"
                        icon={<FavoriteIcon />}
                        label="Bağışlarım"
                        {...a11yProps(0)}
                      />
                      <Tab
                        className="profileTabTitle"
                        icon={<PersonIcon />}
                        label="Hesabım"
                        {...a11yProps(1)}
                      />
                      <Tab
                        onClick={handleLogOut}
                        className="profileTabTitle logout"
                        icon={<LogoutOutlined />}
                        label="Oturumu Kapat"
                      />
                    </Tabs>
                  </Grid>

                  <Grid item xs={12} sm={8} md={9} className="donationTab">
                    <TabPanel value={value} index={0} as="div">
                      <MyDonation
                        myDonation={myDonation}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={1} as="div">
                      <GeneralInfo
                        data={data && data}
                        token={cookies.token}
                        setData={setData}
                        fetchUserData={fetchUserData}
                      />
                    </TabPanel>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default Profile;
