import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import React, { useState } from "react";
import api from "../services/api";

const MyDonationCard = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [transaction, setTransaction] = useState("");
  const [loader, seLoader] = useState(false);
  const [done, setDone] = useState([]);
  const handleClickOpen = (scrollType, data) => () => {
    setOpen(true);
    setScroll(scrollType);
    fetchDonation(data);
  };
  var cookies = document.cookie
    .split(";")
    .reduce(
      (ac, str) =>
        Object.assign(ac, { [str.split("=")[0].trim()]: str.split("=")[1] }),
      {}
    );

  const fetchDonation = async (transaction) => {
    seLoader(true);
    const headers = {
      headers: {
        Authorization: "Bearer " + cookies.token,
      },
    };
    try {
      let { data } = await api.get(
        `/api/payment/transactions/${transaction.id}`,
        headers
      );
      if (data) {
        setTransaction(data);
        setDone(data && data.donations);
        seLoader(false);
      }
    } catch (error) {}
  };

  const handleClose = () => {
    setOpen(false);
  };

  console.log(transaction);

  const {
    first_name,
    last_name,
    email,
    phone_number,
    amount,
    merchant_order_id,
    message,
    organization_name,
    group_name,
    donation_platform,
    status_code,
    status_code_description,
  } = transaction;

  return (
    <>
      <div
        className="donation-card-area"
        onClick={handleClickOpen("paper", data)}
      >
        <h3>{data.first_name}</h3>
        <div className="donation-card-details">
          <span>
            {data.is_complete ? (
              <p className="statusTagSuccess">Ödeme Tamamlandı</p>
            ) : (
              <p className="statusTagPending">Ödeme Yapılırken Hata</p>
            )}
          </span>
          <span>{String(data.date).substring(0, 10)}</span>
          <span> {data.amount} ₺ </span>
        </div>
      </div>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="transactionDialog"
        >
          <DialogTitle id="scroll-dialog-title">İşlem Detayları</DialogTitle>
          <DialogContent
            className="transection-details"
            dividers={scroll === "paper"}
          >
            {loader ? (
              <p> Loading....</p>
            ) : (
              <>
                {
                  <div className="transactionMain">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={6}>
                        <div className="trnasactionInCol">
                          <h3>Bağış Tipi</h3>
                          <span>
                            {" "}
                            {group_name && !organization_name
                              ? "Grup"
                              : organization_name && !group_name
                              ? "Organizasyon"
                              : "Bireysel"}
                          </span>
                          <span>{""}</span>
                        </div>

                        <div className="trnasactionInCol">
                          <h3>Bağışçı Bilgileri</h3>
                          {first_name} {last_name}
                          <span>{email}</span>
                          <span>{phone_number}</span>
                          {group_name && !organization_name
                            ? `Grup Adı: ${group_name}`
                            : organization_name && !group_name
                            ? `Kurum Adı: ${organization_name}`
                            : "Bireysel"}
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <div className="trnasactionInCol">
                          <h3>İşlem Bilgileri</h3>
                          <span>
                            <span>Platform: {donation_platform}</span>
                          </span>
                          <span>İşlem ID: {merchant_order_id}</span>
                          <span>
                            Banka Cevabı: {status_code} -{" "}
                            {status_code_description}
                          </span>
                        </div>
                        {message && (
                          <div className="trnasactionInCol">
                            <h3>Mesajınız</h3>
                            <span>{message}</span>
                          </div>
                        )}
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <div className="trnasactionInCol">
                          <h3>Bağışlar</h3>
                          {done &&
                            done.map((d, key) => (
                              <div className="donations_list_card" key={key}>
                                <span>{d.donation_item.name}</span>
                                <span>{d.amount} ₺</span>
                              </div>
                            ))}
                        </div>
                      </Grid>

                      <h2>Toplam İşlem Tutarı : {amount} ₺</h2>
                    </Grid>
                  </div>
                }
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} className="donation-details-btn">
              Kapat
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default MyDonationCard;
