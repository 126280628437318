import React from "react";
import { CloseOutlined } from "@mui/icons-material";
import { CardMedia } from "@mui/material";

const CartDescription = ({ handleClose, desciptionData }) => {
  return (
    <>
      <div className="cardModal">
        <div className="card_modal_box">
          <div className="card_modal_box_header">
            <CloseOutlined
              style={{ cursor: "pointer" }}
              onClick={handleClose}
            />
          </div>
          <div className="cart_modal_box_content">
            <div className="image">
              <CardMedia
                component="img"
                height="100%"
                image={desciptionData.image}
                title="image"
              />
            </div>
            <h4 className="cardModal_heading">{desciptionData.name}</h4>
            <p className="cardModal_details">
              {desciptionData.description}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartDescription;
