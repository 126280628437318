import { Container } from "@mui/material";
import React from "react";
import Layout from "../../layout";

const AboutUs = () => {
  return (
    <Layout>
        <Container>
          <div className="about-page">
            <h3 className="about-page-title">Hakkımızda</h3>
            <p className="about-page-para">
              Türkiye'nin ilmi, kültürel, sosyal ve ekonomik kalkınmasına yardımcı
              olacak, bilgili ve aydın gençlerin yetişmesine maddi ve manevi
              katkıda bulunmak için çıktığımız bu yolda;
            </p>
            <p className="about-page-para">
              İnsanımızın vatanına, milletine ve tüm insanlığa faydalı olmalarını
              sağlamak amacıyla milli, manevi, ahlaki, dini ve örfi gelişimleri
              için gayret içerisindeyiz. <br /> <br /> Yasalarımızda Vakıf Tüzel
              kişilikleri için öngörüldüğü ölçülerde; eğitim, öğretim, sağlık,
              sosyal, kültürel, tarihi ve dini alanlarda hizmet verecek tesisler
              kurarak, bunun yanında değerlerimize uygun görülen mevcut kurum ve
              çalışmaları da desteklemekten kaçınmıyoruz. Proje kapsamlı tüm
              ortaklıklara gönüllü katkı sağlıyoruz. Özellikle toplumun temeli
              olan aileye yönelik eğitim çalışmaları yapıyoruz. Bağların
              kuvvetlenmesi, sosyal dayanışmanın artması, millet bilincinin
              pekişmesi için çeşitli etkinlik ve programlar yapıyoruz. Fakir,
              muhtaç, kimsesiz ve düşkünlere, insana yakışır bir şekilde yaşam
              standardı sağlayacak sağlık ve sosyal yardımlarda bulunuyor,
              beslenme, giyecek gibi ihtiyaçlarını karşılayacak organizasyonlar
              gerçekleştiriyoruz. Başarılı gençlerin eğitiminin önünde
              karşılaştıkları her türlü engeli kaldırmak için destek olup,
              imkanlar oluşturuyoruz.
            </p>
            <p className="about-page-para">
              Başarılı ve ihtiyaç sahibi öğrencilerin yurtiçi ve yurtdışında
              göreceği akademik eğitimleri için burs veriyor, bilimsel çalışma ve
              araştırma yapmalarını teşvik ediyoruz. İhtiyaç sahibi gençlerin
              evlenmelerine yardımcı oluyoruz. Türk-İslam Medeniyetinin zengin
              kültürünü yaşatmak adına gerektiğinde akademik araştırmalar
              yaptırarak, bu çalışmaları yazılı eser haline getiriyoruz.
              İbadethane, okul ve külliyeler inşa ediyoruz. Bunların iaşe ve ibate
              ihtiyaçlarını karşılıyoruz. Hizmet oluşturduğumuz yerlerde yaşayan
              çevre halkının, insan ve çevre sağlığı konusunda bilgilendirilmesi
              için araştırma ve çalışmalarda bulunuyor, bu amaçla ilgili resmi ve
              özel kurum ve kuruluşlarla ortaklaşa faaliyetlerde bulunuyoruz.
            </p>
            <p className="about-page-para">
              Vakfımızın kuruluş ve hizmet amaçlarına uygun olarak faaliyet
              gösteren diğer kurum ve kuruluşlarla her türlü maddi ve manevi
              ilişkiler kurarak, proje paydaşlığı yapıyoruz.
            </p>
            <br />
            <p className="about-page-para">
              "Amacımız tabela vakfı olmak değil, hizmette öncü vakıf olmaktır."
            </p>
            <h3 className="about-page-title">Tarihçemiz</h3>
            <p className="about-page-para">
              "İhya İlim Hizmet ve Yardımlaşma Vakfı 2013 Yılında Sümbül Efendi
              Camii Baş İmam Hatibi İbrahim Yıldırım ve Sümbül Efendi Camii
              cemaatinin katkılarıyla Kocamustafapaşa/Fatihte kuruldu. Vakfımız;
              seçkin, kültürlü ve dayanışma felsefesini gönüllerine işlemiş
              insanların birlikteliği ile oluşmuştur. İnsanoğluna layık olduğu
              hizmeti verebilmek ve Geleceği İnşa Edecek Nesillere, kalıcı
              projelerle destek verecek, gönüllülük esasına dayalı bir oluşumdur."
            </p>
          </div>
        </Container>
        <div className="contact-bg"></div>
    </Layout>
  );
};

export default AboutUs;
