import { Backdrop, CircularProgress, Container, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import api from "../services/api";
import CardReactFormContainer from "card-react";
import { CurrentUserStore } from "../hooks/auth/currentUser";
import { toast } from "react-toastify";

const PayementInfo = ({ handleNext, handleBack, token, handleDeleteAll }) => {
  const { userState } = useContext(CurrentUserStore);
  const [vcardError] = useState("");
  const [myCartItems, setMyCartItems] = useState([]);
  const [openBackdrop, setBackdrop] = React.useState(false);
  const [total, setTotal] = useState(0);

  var cookies = document.cookie
    .split(";")
    .reduce(
      (ac, str) =>
        Object.assign(ac, { [str.split("=")[0].trim()]: str.split("=")[1] }),
      {}
    );
  const [card, setCard] = useState({
    cardname: "",
    cardnumber: "",
    cardcvc: "",
    cardexpr: "",
    cmsg: "",
  });

  const errorToster = (e) => {
    return toast.error(e);
  };

  const errorObjectToster = (obj) => {
    Object.values(obj).map((err) => {
      errorToster(err[0]);
    });
  };

  useEffect(() => {
    const headers = {
      headers: {
        Authorization: "Bearer " + userState.token,
      },
    };
    const fetchData = async () => {
      try {
        let { data } = await api.get("/api/payment/cart/", headers);
        let myCartItems = data.cart_items.map((e) => {
          return {
            donation_item: e.donation_item,
            amount: e.amount,
          };
        });
        setMyCartItems(myCartItems);
      } catch (error) {}
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (userState.token) {
      const totalAmount = myCartItems?.reduce(
        (p, c) => p + Number(c.amount),
        0
      );
      setTotal(totalAmount);
    } else {
      const cartFromStorage = JSON.parse(localStorage.getItem("cartItems"));
      const totalAmount = cartFromStorage?.reduce(
        (p, c) =>
          c.quantity_price
            ? p + Number(c.quantity_price)
            : p + Number(c.amount),
        0.0
      );
      setTotal(totalAmount);
    }
  }, [myCartItems, userState.token]);

  const { cardname, cardnumber, cardcvc, cardexpr, cmsg } = card;

  const handleContinue = () => {
    if (cardname && cardnumber && cardcvc && cardexpr) {
      handlePaymentSubmit(handleNext);
    } else {
      errorToster("Card information is missing!");
    }
  };

  const handlePaymentSubmit = async () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    setBackdrop(true);

    const headers = {};
    if (cookies.token !== undefined) {
      headers.headers = {
        Authorization: "Bearer " + cookies.token,
      };
    }

    // Assuming cartItems is in the correct format already
    const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];

    const donationItems = cartItems.map((item) => ({
      donation_item: item.id,
      amount: item.quantity_price ? item.quantity_price : item.dynamic_price,
    }));

    const paymentBody = constructPaymentBody(userInfo, donationItems);

    try {
      await sendPayment(paymentBody, headers);
    } catch (error) {
      setBackdrop(false);
      console.error(error.response);
      errorObjectToster(error?.response?.data); // Update error handling if needed
    }
  };

  function constructPaymentBody(userInfo, donationItems) {
    const commonFields = {
      phone_number: userInfo?.phone_number,
      first_name: userInfo?.first_name,
      last_name: userInfo?.last_name,
      email: userInfo?.email,
      card_number: cardnumber,
      card_holder_name: cardname,
      card_expiry: cardexpr.split(" ").join(""),
      card_cvc: cardcvc,
      message: cmsg,
      donations: donationItems,
    };

    // generate user account type
    if (!userInfo.group_name && !userInfo.corporate_name) {
      userInfo.accountType = "personal";
    } else if (!userInfo.group_name && userInfo.corporate_name !== null) {
      userInfo.accountType = "corporate";
    } else if (userInfo.group_name !== null && !userInfo.corporate_name) {
      userInfo.accountType = "group";
    }

    if (userInfo.accountType === "personal") {
      return commonFields;
    } else if (userInfo.accountType === "group") {
      return {
        ...commonFields,
        group_name: userInfo.group_name,
      };
    } else if (userInfo.accountType === "corporate") {
      return {
        ...commonFields,
        organization_name: userInfo.corporate_name,
      };
    } else {
      throw new Error("Unsupported account type");
    }
  }

  async function sendPayment(paymentBody, headers) {
    const response = await api.post(
      "/api/payment/payment/",
      paymentBody,
      headers
    );

    const newResponse = new Response(response.data, {
      status: response.status,
      statusText: response.statusText,
      headers: response.headers,
    });

    const data = await newResponse.blob();
    const url = URL.createObjectURL(data);
    window.location.href = url; // display response in browser
    setBackdrop(false);
  }

  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);
  useEffect(() => {
    let errorDetails = queryParams.get("details");
    let errorDescription = queryParams.get("bank_status_code_description");

    toast.error(errorDetails);
    toast.error(errorDescription);
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="donation_payment_info">
        <Container className="paymentcont">
          <Grid container>
            <Grid item xs={12} sm={12} md={8}>
              <div className="donation_payment_info_box">
                {/* <PaymentCardDetails setCard={setCard} card={card} /> */}
                <div id="card-wrapper"></div>
                <CardReactFormContainer
                  container="card-wrapper"
                  formInputsNames={{
                    number: "CCnumber",
                    expiry: "CCexpiry",
                    cvc: "CCcvc",
                    name: "CCname",
                    msg: "CCmsg",
                  }}
                  formatting={true}
                  classes={{
                    valid: "valid-input",
                    invalid: "invalid-input",
                  }}
                  initialValues={{
                    number: "",
                    cvc: "",
                    expiry: "",
                    name: "",
                    msg: "",
                  }}
                >
                  <form className="cardetails-form vcard-form">
                    <Grid container>
                      <Grid
                        className="vcard"
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        columnGap={2}
                      >
                        <label className="cardetails-label">
                          Kart Üzerindeki İsim:
                        </label>
                        <input
                          type="text"
                          className="cardetails-input vcard"
                          placeholder="Kart üzerindeki ismi giriniz"
                          name="CCname"
                          onChange={(e) =>
                            setCard({
                              ...card,
                              cardname: e.target.value,
                            })
                          }
                        />
                      </Grid>
                      <Grid className="vcard" item xs={12} sm={6} md={6}>
                        <label className="cardetails-label">
                          Kart Numarası:{" "}
                        </label>
                        <input
                          type="text"
                          className="cardetails-input vcard"
                          data-mask="0000 0000 0000 0000"
                          placeholder="XXXX-XXXX-XXXX-XXXX"
                          name="CCnumber"
                          maxLength={19}
                          onChange={(e) =>
                            setCard({
                              ...card,
                              cardnumber: e.target.value,
                            })
                          }
                        />
                      </Grid>
                      <Grid className="vcard" item xs={12} sm={6} md={6}>
                        <label className="cardetails-label">
                          Geçerlilik Tarihi:
                        </label>
                        <input
                          type="text"
                          className="cardetails-input vcard"
                          placeholder="mm / yy"
                          maxlength="7"
                          name="CCexpiry"
                          onChange={(e) =>
                            setCard({
                              ...card,
                              cardexpr: e.target.value,
                            })
                          }
                        />
                      </Grid>
                      <Grid className="vcard" item xs={12} sm={6} md={6}>
                        <label className="cardetails-label">Cvv:</label>
                        <input
                          type="password"
                          className="cardetails-input vcard"
                          data-mask="000"
                          placeholder="000"
                          maxlength="3"
                          pattern="[0-9][0-9][0-9]"
                          name="CCcvc"
                          onChange={(e) =>
                            setCard({
                              ...card,
                              cardcvc: e.target.value,
                            })
                          }
                        />
                      </Grid>
                      <Grid className="vcard " item xs={12} sm={12} md={12}>
                        <label className="cardetails-label">
                          Varsa Mesajınız
                        </label>
                        <textarea
                          type="text"
                          className="cardetails-input vcard vcard_message"
                          maxlength="50"
                          name="CCmsg"
                          onChange={(e) =>
                            setCard({
                              ...card,
                              cmsg: e.target.value,
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                  </form>
                </CardReactFormContainer>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div className="donation_payment_info_total cart-payment-info">
                <h1 className="intotalHeading">Bağış Sepeti</h1>
                <div className="intotalDonation">
                  <span>Toplam Bağış :</span>{" "}
                  <span className="mainPrice">
                    <span className="liraSymbol">₺</span> {total}.00
                  </span>
                </div>
                <div className="intotalBtns">
                  <button className="completepayment back" onClick={handleBack}>
                    Geri
                  </button>
                  <button
                    className="completepayment next"
                    onClick={handleContinue}
                  >
                    Ödemeyi Tamamla
                  </button>
                </div>
              </div>
              {/* <div className="reCaptcha_container">
                <ReCAPTCHA className="reCaptcha_div"
                  // sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                  onChange={onChange}
                />
              </div> */}
            </Grid>
          </Grid>
        </Container>

        {vcardError}
      </div>
    </>
  );
};

export default PayementInfo;
